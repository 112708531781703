import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker as DatePickerNew, Select, message, Space } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { baseUrl } from '../../../baseUrl';
import CalendarModal from './CalendarModal';
import timezone from 'dayjs/plugin/timezone';
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePickerNew;
const { Option } = Select;

const EditItem = () => {
  const [items, setItems] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM'));
  const [hostel, setHostel] = useState('LH');

  useEffect(() => {
    fetchItems();
  }, [selectedMonth, hostel]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inmate/non-veg-items`, { params: { hostel: "LH", selectedMonth } });
      setItems(response.data);
    } catch (error) {
      message.error('Failed to fetch items');
    }
  };

  const handleSave = async (key) => {
    try {
      const row = items.find(item => item.item_id === key);
      console.log('row', row)
      const formattedRow = {
        ...row,
        booking_start: dayjs(row.booking_start).format('YYYY-MM-DD'),
        booking_end: dayjs(row.booking_end).format('YYYY-MM-DD'),
        dates_served: row.dates_served.map(date => dayjs(date).format('YYYY-MM-DD')),
      };
      console.log('formattedRow', formattedRow)
      await axios.put(`${baseUrl}/inmate/non-veg-items/${key}`, formattedRow);
      setEditingKey('');
      message.success('Item updated successfully');
      fetchItems();
    } catch (error) {
      message.error('Failed to update item');
    }
  };

  const handleEdit = (key) => {
    setEditingKey(key);
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const format = "MM/DD/YYYY";


  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < dayjs().endOf('day');
    return current && current < dayjs().endOf('day');
  };
  const isEditing = (record) => record.item_id === editingKey;

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      // const newDates = [...items];
      // dates.forEach((date, index) => {
      //   if (date && !disabledDate(date)) {
      //     newDates[index] = date;
      //   }
      // });

      // setItems(newDates);
    }
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      width: '30%',
      editable: true,
    },
    {
      title: 'Booking Period',
      dataIndex: 'booking_period',
      width: '30%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (

          <DatePicker
            value={[(record.booking_start), (record.booking_end)]}
            onChange={(dates, dateString) => {
              console.log('dateString', dateString)
              console.log('dates', dates)
              const updatedItems = items.map(item => {
                if (item.item_id === record.item_id) {
                  return {
                    ...item,
                    booking_start: dateString.validatedValue[0],
                    booking_end: dateString.validatedValue[1]
                  };
                }
                return item;
              });
              setItems(updatedItems);
            }}
            minDate={new DateObject()}
            maxDate={new DateObject().toLastOfMonth()}
            className="w-full py-4"
            // onChange={onRangeChange}
            range
          />
        ) : (
          <div>
            <div>Start: {dayjs(record.booking_start).format("DD-MM-YYYY")}</div>
            <div>End: {dayjs(record.booking_end).format("DD-MM-YYYY")}</div>
          </div>
        );
      }
    },
    {
      title: 'Dates Serveds',
      dataIndex: 'dates_serveds',
      width: '30%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          // <DatePicker
          //   multiple
          //   value={record.dates_served.map(date => dayjs(date))}
          //   onChange={(dates,datesString) => {
          //     console.log('dateString', datesString)
          //     console.log('dates', dates)
          //     const updatedItems = items.map(item => {
          //       console.log('record.item_id', record.item_id)
          //       console.log('item.item_id', item.item_id)
          //       if (item.item_id === record.item_id) {

          //         const existingDisabledDates = item.dates_served.filter(date => 
          //           dayjs(date).isBefore(dayjs().add(1, 'days'))
          //         );
          //         // if(datesString.includes())
          //         // if(dayjs(date).isBefore(dayjs().add(1, 'days')))
          //         console.log('existingDisabledDates', existingDisabledDates)
          //         return {
          //           ...item,
          //           dates_served: [...datesString,...existingDisabledDates],
          //         };
          //       }
          //       console.log('item', item)
          //       return item;
          //     })
          //     console.log('updatedItems', updatedItems)
          //     setItems(updatedItems);
          //   }}
          //   disabledDate={disabledDate}

          // />
          // <Space direction="vertical" size={12}>
          //   {record.dates_served.map((date, index) => (
          //     <RangePicker
          //       key={index}
          //       value={[record.dates_served[index], record.dates_served[index + 1]]}
          //       disabledDate={disabledDate}
          //       onChange={handleDateChange}
          //       disabled={[disabledDate(record.dates_served[index]), false]}
          //     />
          //   ))}
          // </Space>
          // <MultipleDatePicker defaultValues={record.dates_served.map(date => dayjs(date))} />
          <div style={{ textAlign: "center" }}>
            <DatePicker
              value={record.dates_served}
              onChange={(datesString) => {
                console.log('datesString', datesString)
                const updatedItems = items.map(item => {
                  console.log('record.item_id', record.item_id)
                  console.log('item.item_id', item.item_id)
                  if (item.item_id === record.item_id) {

                    const existingDisabledDates = item.dates_served.filter(date =>
                      dayjs(date).isBefore(dayjs().add(1, 'days'))
                    );
                    console.log('existingDisabledDates', existingDisabledDates)
                    return {
                      ...item,
                      dates_served: datesString,
                    };
                  }
                  console.log('item', item)
                  return item;
                })
                console.log('updatedItems', updatedItems)
                setItems(updatedItems);
              }}
              mapDays={({ date }) => {
                console.log('date', date)
                const today = new DateObject().add(1,'day')
                let isBeforeToday = today > date

                if (isBeforeToday) return {
                  disabled: true,
                  style: { color: "#ccc" },
                  onClick: () => alert("weekends are disabled")
                }
              }}
              multiple
              sort
              format={format}
              calendarPosition="bottom-center"
              plugins={[<DatePanel />]}
            />
          </div>

        ) : (
          <CalendarModal dates={record.dates_served.map(date => dayjs(date).format('YYYY-MM-DD'))} />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => handleSave(record.item_id)} style={{ marginRight: 8 }}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </span>
        ) : (
          <Button disabled={editingKey !== ''} onClick={() => handleEdit(record.item_id)}>
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div className="overflow-x-scroll">
      <div className="mb-4 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
      <DatePickerNew
                      picker="month"
                      onChange={(date, dateString) => setSelectedMonth(dateString)}
                      className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                      minDate={dayjs().add(1, 'month')}
                    />
        {/* <Select
          placeholder="Select a month"
          onChange={setSelectedMonth}
          className="w-full md:w-1/3"
        >
          {[...Array(12)].map((_, i) => {
            const date = dayjs().month(i);
            return (
              <Option key={i} value={date.format('YYYY-MM')}>
                {date.format('MMMM YYYY')}
              </Option>
            );
          })}
        </Select> */}
      </div>
      <Table
        className='h-auto'
        columns={columns}
        dataSource={items}
        rowKey="item_id"
        pagination={false}
      />
    </div>
  );
};

export default EditItem;

// CSS Styles

