import React, { useState } from "react";
import CreateUserWarder1 from "../../components/CreateUserWarder1";
import { motion } from "framer-motion";
import AddUserExcel from "../../components/AddUserExcel";
import BulkUpdateProfiles from "./Components/BulkUpdateProfiles";
import InmateProfile from "./Components/InmateProfile";
import ViewParentDetails from "./Components/ViewParentDetails";
import RelievedInmatesTable from "./Components/RelievedInmatesTable";
import CreateStudentUserWD from "./Components/CreateStudentUserWD";
import DirectAllotmentForm from "./Components/DirectAllotmentForm";
import TemplateBuilder from "./Components/TemplateBuilder";

function ManageUser() {
  const [tabSelected, setTabSelected] = useState(5);
  const [userData, setUserData] = useState(null);

  const handleData = (data) => {
    if (data != null) {
      setUserData(data);
    }
  };
  return (
    <div className="flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll ">
      <div className="flex flex-row justify-between w-11/12 pt-4 items-center">
        <div className="text-xl font-bold md:ml-6 ml-12">Manage User</div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="bg-white border rounded-full w-10 aspect-square" />
          <div>user Name</div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col items-center py-8 space-y-4 w-11/12 md mt-8 bg-white rounded-xl"
      >
        {/* white box nav bar */}
        <div className="flex flex-row justify-between w-11/12 items-center">
          <div className="flex flex-row tex-black text-sm font-bold relative mb-3">

            <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(5);
              }}
            >
              <div>View Parent Details</div>
              <div
                className={
                  tabSelected === 5
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>

          </div>
        </div>
        {tabSelected === 5 && <ViewParentDetails />}
      </motion.div>
    </div>
  );
}

export default ManageUser;
