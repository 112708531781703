import React, { useContext, useState } from 'react';
import { jsPDF } from "jspdf";
import { Download, FileText, Loader2 } from 'lucide-react';
import logo from '../../../icons/Cet_emblem.jpg';
import { baseUrl } from '../../../baseUrl';
import axios from 'axios';
import { UserContext } from '../../../Contexts/UserContext';
import dayjs from 'dayjs'



const CertificateDownloader = () => {

  const { user, setLoading } = useContext(UserContext);
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [purposeInputs, setPurposeInputs] = useState({
    Expense: '',
    NonInmate: '',
    Inmate: ''
  });

  const generatePDF = async (certData) => {
    const doc = new jsPDF();
    let data;
    const response = await axios.get(`${baseUrl}/inmate/get-student-deatils`, { params: { admissionNo: user.user_id } })

    const hostelName = { "LH": "Ladies Hostels", "MH": "Men's Hostel" }
    const proNoun = { "Female": "She", "Male": "He" }
    const department = { 'CE': 'Civil Engineering', 'ME': 'Mechanical Enginnering', 'CS': 'Computer Science Engineering.' }

    if (certData.certificateType == 'NonInmate') {
      data = {
        date: dayjs().format('DD.MM.YYYY'),
        content: `Certified that ${user.name} (College Ad. No. ${user.user_id})  is undergoing ${response.data.programme} studies under Dicipline  ${response.data.department}. ${proNoun[response.data.gender]} is a not an inmate of CET Hostel.`,
        purpose: 'is issued on her request for the purpose of ' + purposeInputs.NonInmate,
      }
    }

    // Add font
    doc.addFont('times', 'Times', 'normal');
    doc.setFont('Times');

    // Add logo
    doc.addImage(logo, 'PNG', 10, 10, 30, 30);

    // Header
    doc.setFontSize(16);
    doc.text("HOSTEL OFFICE - COLLEGE OF ENGINEERING", 105, 25, { align: "center" });
    doc.text("THIRUVANANTHAPURAM - 16", 105, 33, { align: "center" });
    doc.setFontSize(12);
    doc.text("Ph: 0471- 2515689, e-mail Id warden@cet.ac.in", 105, 41, { align: "center" });

    // Certificate number and date
    console.log('certData.certificateType', certData.certificateType)
    const res = await axios.get(`${baseUrl}/inmate/get-certificate-number`, { params: { certificateType: certData.certificateType, admissionNo: user.user_id, purposeInput: purposeInputs.NonInmate } })
    console.log('res', res)
    const { certificateNumber } = res.data
    doc.text(`No. ${certificateNumber}`, 20, 55);
    doc.text(`Dated: ${data.date}`, 190, 55, { align: "right" });

    // Title
    doc.setFontSize(16);
    doc.text("CERTIFICATE", 105, 70, { align: "center" });

    // Content
    doc.setFontSize(12);
    const contentLines = doc.splitTextToSize(data.content, 160);
    doc.text(contentLines, 20, 85, { align: "justify", maxWidth: 175 });

    let yPos = 85 + (contentLines.length * 7);

    // Purpose
    doc.text(`This certificate ${data.purpose}`, 20, yPos);

    // Signature
    const warden = "Dr. SUNILKUMAR S";
    doc.text(warden, 190, 220, { align: "right" });
    doc.text("WARDEN", 190, 230, { align: "right" });

    // To
    doc.text("To,", 20, 255);
    doc.text(user.name, 35, 265);

    doc.setDrawColor(255, 0, 0);
    doc.line(5, 275, 205, 275);
    doc.line(5, 276, 205, 276);


    doc.setFontSize(10);
    doc.text('This is a computer-generated document. No signature is requireds.', 50, 283);
    doc.text('You can check the validity of this certificate by visting https://cethostel.in/verify-cerificate.', 45, 290);

    return doc;
  };
  const certificates = [
    // {
    //   id: 1,
    //   name: 'Monthly Expense ',
    //   description: 'Download a detailed breakdown of your monthly hostel expenses',
    //   certificateType: 'Expense',
    //   icon: FileText
    // },
    {
      id: 2,
      name: 'Non-Inmate ',
      description: 'Verify your non-resident status at the hostel',
      certificateType: 'NonInmate',
      icon: FileText
    },
    // {
    //   id: 3,
    //   name: 'Inmate ',
    //   description: 'Confirm your resident status at the hostel',
    //   certificateType: 'Inmate',
    //   icon: FileText
    // }
  ];
  const handleDownload = async (cert) => {
    try {
      setIsGenerating(true);
      setActiveCard(cert.id);
      const doc = await generatePDF(cert);
      doc.save(`${cert.name}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("There was an error generating the PDF. Please try again.");
    } finally {
      setIsGenerating(false);
      setActiveCard(null);
      setPurposeInputs((prevState) => ({
        ...prevState,
        [cert.certificateType]: ''
      }));
    }
  };
  const handlePurposeChange = (e, certificateType) => {
    setPurposeInputs((prevState) => ({
      ...prevState,
      [certificateType]: e.target.value
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl w-11/12 flex flex-col  justify-between items-center">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Certificate Downloads</h1>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Generate and download official certificates for your hostel-related documentation needs
        </p>
      </div>

      <div className="w-6/12 gap-6 flex justify-center">
        {certificates.map((cert) => (
          <div key={cert.id}>
            {/* Existing card code remains the same */}

            <div className="mt-4">
              <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg mb-4">
                <cert.icon className="w-6 h-6 text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900 mb-2">{cert.name}</h2>
              <p className="text-gray-600 mb-6 text-sm">{cert.description}</p>

              <input
                type="text"
                id={`purpose-${cert.id}`}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={purposeInputs[cert.certificateType]}
                onChange={(e) => handlePurposeChange(e, cert.certificateType)}
                placeholder="Enter the purpose"
              />
            </div>

            <button
              onClick={() => handleDownload(cert)}
              disabled={isGenerating || !purposeInputs[cert.certificateType]}
              className={`w-full flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 
              ${(isGenerating && activeCard === cert.id) || !purposeInputs[cert.certificateType]
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 text-white'
                }`}
            >
              {isGenerating && activeCard === cert.id ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Download className="w-4 h-4 mr-2" />
                  Generate PDF
                </>
              )}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-8 text-center text-sm text-gray-500">
        <p>All certificates are digitally generated
          {/* and include a verification QR code. */}
        </p>
        <p>For any issues, please contact the hostel office.</p>
      </div>
    </div>
  );
};

export default CertificateDownloader;