import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";
import AlertDialog from "../../../components/AlertDialog";
import ConfirmDialog from "../../../components/ConfirmDialog";

const MessEventsManager = () => {
  const { setLoading, user } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("view"); // "add" or "view"
  
  // Add Event States
  const [event_date, set_event_date] = useState("");
  const [book_before_date, set_book_before_date] = useState("");
  const [item, set_item] = useState("");
  const [item_price, set_item_price] = useState(0);
  const [served_time, set_served_time] = useState(-1);
  const [defaultselected, setDefaultSelected] = useState(false);
  
  // View Events States
  const [status, set_status] = useState(2);
  const [mess_special_events, set_mess_special_events] = useState([]);
  
  // Modal States
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const mp = {
    1: "BreakFast",
    2: "Lunch",
    3: "Tea",
    4: "Dinner"
  };

  // Date helper functions
  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return day + "-" + month + "-" + year;
  };

  const getMinDateForEvent = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  };

  const getMinDateForEventBooking = () => {
    const date = new Date();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  };

  const getMaxDateForEventBooking = () => {
    if (!event_date) return "";
    const date = new Date(event_date);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  };

  // Form submission handlers
  const submit_Request_Confirmation = (e) => {
    e.preventDefault();
    if (served_time === -1) {
      setModalHeading("Please choose all Field");
      setModalText("Please check if you have filled all the necessary fields before submission \nPlease correct and try again");
      setOpen1(true);
      return;
    }
    
    const event_date_1 = new Date(event_date);
    const book_before_date_1 = new Date(book_before_date);
    if (event_date_1 <= book_before_date_1) {
      setModalHeading("Invalid date");
      setModalText("Please choose the relevant dates appropriately. The date to book should be prior to the event date");
      setOpen1(true);
      return;
    }
    
    if (item_price <= 0) {
      setModalHeading("Invalid price");
      setModalText("The item price should be greater than zero. Please change it and proceed.");
      setOpen1(true);
      return;
    }
    
    setModalHeading("Confirm Adding Event");
    setModalText(`You are about to add a Special Mess item ${item} for the date ${parseDateDDMMYYYY(event_date)} at time ${mp[served_time]}. Inmates will be able to book for the item before ${parseDateDDMMYYYY(book_before_date)}\nDo you want to confirm?`);
    setOpen2(true);
  };

  const add_mess_special_event = () => {
    setLoading(true);
    axios.post(`${baseUrl}/inmate/add-mess-special-events`, {
      hostel: user.hostel,
      is_added_messbill: false,
      event_date:event_date,
      book_before_date:book_before_date,
      item:item,
      item_price:item_price,
      served_time:served_time,
    }).then((res) => {
      setModalHeading("Event Added");
      setModalText(`The Event has been added successfully. Inmates will be able to book for the event before ${parseDateDDMMYYYY(book_before_date)}\n You can see the booking report in your tab`);
      setOpen1(true);
      set_book_before_date("");
      set_event_date("");
      set_item("");
      set_served_time("");
      // Refresh the events list
      fetchEvents();
    }).catch((err) => {
      setModalHeading("Something went wrong");
      setModalText("Your request could not be added. Please Try Again after sometime \n Thanks for your patience");
      setOpen1(true);
    }).finally(() => {
      setLoading(false);
    });
  };

  // Fetch events
  const fetchEvents = () => {
    setLoading(true);
    axios.get(`${baseUrl}/inmate/get-mess-special-events?status=${status}&&hostel=${user.hostel}`)
      .then((res) => {
        set_mess_special_events(res.data.data);
      })
      .catch((err) => {
        console.log("error occurred ", err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEvents();
  }, [status]);

  return (
    <div className="p-4 w-11/12">
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => setActiveTab("view")}
          className={`px-4 py-2 rounded-lg ${
            activeTab === "view"
              ? "bg-stone-800 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          View Events
        </button>
        <button
          onClick={() => setActiveTab("add")}
          className={`px-4 py-2 rounded-lg ${
            activeTab === "add"
              ? "bg-stone-800 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          Add New Event
        </button>
      </div>

      {activeTab === "add" && (
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="font-semibold text-lg mb-4">Add Mess Special Event</h2>
          <form onSubmit={submit_Request_Confirmation}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block">Event Date:</label>
                <input
                  required
                  type="date"
                  min={getMinDateForEvent()}
                  value={event_date}
                  onChange={(e) => set_event_date(e.target.value)}
                  className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />
              </div>

              <div className="space-y-2">
                <label className="block">Book Before:</label>
                <input
                  required
                  type="date"
                  onChange={(e) => set_book_before_date(e.target.value)}
                  min={getMinDateForEventBooking()}
                  max={getMaxDateForEventBooking()}
                  value={book_before_date}
                  className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                />
              </div>

              <div className="space-y-2">
                <label className="block">Served Time:</label>
                <select
                  required
                  onChange={(e) => set_served_time(e.target.value)}
                  value={served_time}
                  className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                >
                  <option value={-1}>--Select Served Time---</option>
                  <option value={1}>BreakFast</option>
                  <option value={2}>Lunch</option>
                  <option value={3}>Tea</option>
                  <option value={4}>Dinner</option>
                </select>
              </div>

              <div className="space-y-2">
                <label className="block">Item:</label>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => set_item(e.target.value)}
                  className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="block">Item Price (Rs.):</label>
                <input
                  type="number"
                  value={item_price}
                  min={1}
                  onChange={(e) => set_item_price(e.target.value)}
                  className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                  required
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="px-6 py-2 bg-stone-800 text-white rounded-xl hover:bg-stone-700 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}

      {activeTab === "view" && (
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-semibold text-lg">View Mess Special Events</h2>
            <select
              onChange={(e) => set_status(e.target.value)}
              className="w-40 p-2 ring-slate-200 ring-2 rounded-xl outline-none"
              value={status}
            >
              <option value={2}>Upcoming</option>
              <option value={1}>Previous</option>
              <option value={0}>All</option>
            </select>
          </div>

          {mess_special_events.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500">No events found</p>
            </div>
          ) : (
            <div className="space-y-4">
              {mess_special_events.map((event, index) => (
                <Link
                  key={index}
                  to={`/mess-special-events-bookings/${event.id}`}
                  className="block"
                >
                  <div className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-colors">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div>
                        <span className="text-gray-500 text-sm">Item</span>
                        <p className="font-medium">{event.item}</p>
                      </div>
                      <div>
                        <span className="text-gray-500 text-sm">Event Date</span>
                        <p className="font-medium">
                          {parseDateDDMMYYYY(event.event_date)}
                        </p>
                      </div>
                      <div>
                        <span className="text-gray-500 text-sm">Book Before</span>
                        <p className="font-medium">
                          {parseDateDDMMYYYY(event.book_before_date)}
                        </p>
                      </div>
                      <div>
                        <span className="text-gray-500 text-sm">Served At</span>
                        <p className="font-medium">{mp[event.served_time]}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      )}

      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={add_mess_special_event}
      />
    </div>
  );
};

export default MessEventsManager;