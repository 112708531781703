import React, { useState } from "react";
import { motion } from "framer-motion";
import CreateUserWarder1 from "../../components/CreateUserWarder1";
import AddUserExcel from "../../components/AddUserExcel";
import BulkUpdateProfiles from "../Warden/Components/BulkUpdateProfiles";
import InmateProfile from "../Warden/Components/InmateProfile";
import ViewParentDetails from "../Warden/Components/ViewParentDetails";
import RelievedInmatesTable from "../Warden/Components/RelievedInmatesTable";
import CreateStudentUserWD from "../Warden/Components/CreateStudentUserWD";
import DirectAllotmentForm from "../Warden/Components/DirectAllotmentForm";
import PromotionWD from "./Components/PromotionWD";

function ManageUserClerk() {
  const [tabSelected, setTabSelected] = useState(1);
  const [userData, setUserData] = useState(null);

  const handleData = (data) => {
    if (data != null) {
      setUserData(data);
    }
  };
  return (
    <div className="flex flex-col w-full items-center min-h-screen h-full overflow-y-scroll ">
      <div className="flex flex-row justify-between w-11/12 pt-4 items-center">
        <div className="text-xl font-bold md:ml-6 ml-12">Manage User</div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="bg-white border rounded-full w-10 aspect-square" />
          <div>user Name</div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col items-center py-8 space-y-4 w-11/12 md mt-8 bg-white rounded-xl"
      >
        {/* white box nav bar */}
        <div className="flex flex-row justify-between w-11/12 items-center">
          <div className="flex flex-row tex-black text-sm font-bold relative mb-3">
            {/* <div
              className="cursor-pointer "
              onClick={() => {
                setTabSelected(1);
              }}
            >
              <div>Add User</div>
              <div
                className={
                  tabSelected === 1
                    ? "mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>

            <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(2);
              }}
            >
              <div>Bulk Add User</div>
              <div
                className={
                  tabSelected === 2
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div> */}
            <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(3);
              }}
            >
              <div>Bulk Update Profiles</div>
              <div
                className={
                  tabSelected === 3
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>
            <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(4);
              }}
            >
              <div>Student Profile</div>
              <div
                className={
                  tabSelected === 4
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>
            {/* <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(5);
              }}
            >
              <div>View Parent Details</div>
              <div
                className={
                  tabSelected === 5
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div> */}
            <div
              className="ml-5 cursor-pointer"
              onClick={() => {
                setTabSelected(6);
              }}
            >
              <div>View Relieved Inmate</div>
              <div
                className={
                  tabSelected === 6
                    ? "mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>
            <div
              className="ml-5 cursor-pointer "
              onClick={() => {
                setTabSelected(7);
              }}
            >
              <div>Create Student User</div>
              <div
                className={
                  tabSelected === 7
                    ? "mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>
            <div
              className="ml-5 cursor-pointer "
              onClick={() => {
                setTabSelected(9);
              }}
            >
              <div>Promote Inmates</div>
              <div
                className={
                  tabSelected === 9
                    ? "mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div>
            {/* <div
              className="ml-5 cursor-pointer "
              onClick={() => {
                setTabSelected(8);
              }}
            >
              <div>Direct Admission</div>
              <div
                className={
                  tabSelected === 8
                    ? "mt-2 h-1 self-center w-10/12 bg-stone-800 rounded-full"
                    : ""
                }
              />
            </div> */}
          </div>
        </div>
        {tabSelected === 1 && <CreateUserWarder1 userData={userData} />}
        {tabSelected === 2 && <AddUserExcel setTabSelected={setTabSelected} data={handleData} />}
        {tabSelected === 3 && <BulkUpdateProfiles setTabSelected={setTabSelected} data={handleData} />}
        {tabSelected === 4 && <InmateProfile setTabSelected={setTabSelected} data={handleData} />}
        {tabSelected === 5 && <ViewParentDetails setTabSelected={setTabSelected} data={handleData} />}
        {tabSelected === 6 && <RelievedInmatesTable setTabSelected={setTabSelected} data={handleData} />}
        {tabSelected === 7 && <CreateStudentUserWD />}
        {tabSelected === 8 && <DirectAllotmentForm />}
        {tabSelected === 9 && <PromotionWD />}
      </motion.div>
    </div>
  );
}

export default ManageUserClerk;
