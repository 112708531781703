import React, { useState } from 'react';
import { DatePicker, Table, Typography } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import dayjs from 'dayjs';

const { Title } = Typography;

const BillsComponent = () => {
  const [bills, setBills] = useState([]);

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
    {
      title: 'Receipt No',
      dataIndex: 'receipt_no',
      key: 'receipt_no',
    },
    {
      title: 'Receipt Date',
      dataIndex: 'receipt_date',
      key: 'receipt_date',
      render: (text =>  text ? dayjs(text).format('YYYY-MM-DD') : 'N/A')

    },
  ];

  const handleDateChange = async (date) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      try {
        const response = await axios.get(`${baseUrl}/clerk-a1/bills`, { params: { date: formattedDate } });
        setBills(response.data);
      } catch (error) {
        console.error('Error fetching bills:', error);
      }
    } else {
      setBills([]);
    }
  };

  return (
    <div className='w-11/12'>
      <Title level={2}>Non-Veg Supply Bills</Title>
      <DatePicker onChange={handleDateChange} style={{ marginBottom: '20px' }} />
      <Table columns={columns} dataSource={bills} rowKey="item_id" />
    </div>
  );
};

export default BillsComponent;