import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../baseUrl";
import { UserContext } from "../../Contexts/UserContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from "file-saver";
import AlertDialog from "../../components/AlertDialog";
import ConfirmDialog from "../../components/ConfirmDialog";
import dayjs from 'dayjs'

const Mess_Special_Event_BookingList = () => {
  const { id } = useParams();
  const { user, setLoading } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [event_details, set_event_details] = useState({})
  const [event_price, set_event_price] = useState(0);
  const [booking_details, set_booking_details] = useState([]);
  const [total, setTotal] = useState(0)


  const parseDateDDMMYYYY = (inputdate) => {
    const date = new Date(inputdate);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return day + "-" + month + "-" + year;
  };
  const downloadPDF = () => {
    const pdf = new jsPDF();
    if (booking_details.length < 1) return alert("nothing to download");
    const date = new Date();
    const today = parseDateDDMMYYYY(date);

    const title = `BOOKING LIST FOR THE ITEM  ${event_details.item} on ${parseDateDDMMYYYY(event_details.event_date)} `;
    pdf.text(title, 14, 15);

    const columns = [
      "index",
      "id",
      "hostel_admission_no",
      "name",
      "quantity",
      "price",
      "is_messout"
    ];

    const header = [
      "SL.No",
      "Booking ID",
      "Hostel Admission No",
      "Name",
      "Quantity",
      "Price",
      "Is Messout"
    ];

    let total_Quantity = 0;
    const rows = booking_details.map((row, index) => {
      total_Quantity = total_Quantity + row["quantity"]
      return columns.map((column, i) => {
        if (i == 0)
          return index + 1
        if (column == "price")
          return row["quantity"] * event_price
        return row[column]
      })
    }
    );
    pdf.autoTable({
      head: [header],
      body: rows,
      startY: 25,
    });

    pdf.setFontSize(13)
    pdf.text(`Total Quantity = ${total_Quantity} `, 14, pdf.autoTable.previous.finalY + 10);
    pdf.text(`Total Price = ${total_Quantity * event_price} `, 14, pdf.autoTable.previous.finalY + 20);
    pdf
      .save(`Mess Special Event Booking.pdf`, { returnPromise: true, type: "blob" })
      .then((blob) => {
        FileSaver.saveAs(blob, `Mess Special Event Booking.pdf`);
      });
  };
  useEffect(() => {
    setLoading(true)
    axios.get(`${baseUrl}/inmate/get-event-price?event_id=${id}`).then((res) => {
      console.log(res.data.event_data)
      set_event_price(res.data.data)
      set_event_details(res.data.event_data)
    }).catch((err) => {

    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inmate/get-event-bookings?event_id=${id}`)
      .then((res) => {
        set_booking_details(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const update_price_confirm = (e) => {
    e.preventDefault()
    if (event_price <= 0) {
      setModalHeading("Invalid price");
      setModalText("Price cannot be zero or negative please update it and proceed ");
      setOpen(true)
      return;
    }
    setModalHeading("Are you sure ?")
    setModalText("Are you sure you want to update the price for the event ")
    setOpen1(true)

  }

  const update_event_price = () => {
    setLoading(true);
    axios.post(`${baseUrl}/inmate/update-event-price`, { user_id: user.user_id, event_id: id, event_price: event_price }).then((res) => {
      setModalHeading("Price update ");
      setModalText("The price for the event has been updated successfullly")
      setOpen(true);
    }).catch((err) => {
      setModalHeading("Something went wrong")
      setModalText("Could not update price please try again later")
      setOpen(true)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className="flex flex-col w-full items-center mb-4  ">
      <div className="w-11/12 flex flex-col items-center py-8 space-y-4 w-11/12 mt-8 bg-white rounded-xl  ">
        <h2 className="font-semibold text-lg m-2">
          Mess Special Events Bookings for the item {event_details && event_details.item} on {event_details && parseDateDDMMYYYY(event_details.event_date)}
        </h2>
        <form onSubmit={update_price_confirm}>
          <div className="justify-center">
            <div className="grid grid-cols-2 gap-4 mb-3">
              <label className="mt-2 font-bold" htmlFor="">
                Update Event Price
              </label>{" "}
              <input
                min={1}
                value={event_price}
                onChange={(e) => {
                  set_event_price(e.target.value)
                }}
                required
                type="number"
                className="w-12/12 py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-center mb-5">
              <button
                className="bg-stone-800 text-white p-2 rounded-lg text-sm mr-5"
                type="submit"
              >
                Update Price
              </button>
            </div>
          </div>
        </form>
        <div className="w-full">
          <div className="flex items-center justify-end m-5">
            <button
              disabled={dayjs().isBefore(dayjs(event_details.event_date).subtract(1, 'day'))}
              className={dayjs().isBefore(dayjs(event_details.event_date).subtract(1, 'day')) ? "bg-[#e0e0e0] p-3 text-[#a6a6a6] rounded-lg text-sm" : "bg-stone-800 text-white p-3 rounded-lg text-sm "}
              onClick={() => {
                downloadPDF();
              }}
            >
              Download as PDF
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex m-5 items-center justify-start mb-5">
            <p className="font-bold">Total Bookings : {booking_details.length} </p>
          </div>
        </div>
        <div className="w-full">
          <div className="flex m-5 items-center justify-start mb-5">
            <p className="font-bold">Total Item Needed : {total} </p>
          </div>
        </div>

        <div className="overflow-x-scroll w-11/12">
          <table className="w-full relative table-auto mr-2 ">
            <tr className="rounded-xl p-3 bg-primary text-center">
              <th className="p-3">Sl.No</th>
              <th className="p-3">Booking Id</th>
              <th className="p-3">Hostel Admission No</th>
              <th className="p-3">Name</th>
              <th className="p-3">Quantity</th>
              <th className="p-3">Booked Date</th>
              <th className="p-3">Is Messout</th>
            </tr>

            {booking_details.length > 0 &&
              booking_details.map((booking, index) => {

                return (
                  <tr
                    className="border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                    key={index}
                  >
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">{booking.id}</td>
                    <td className="p-3">{booking.hostel_admission_no}</td>
                    <td className="p-3">{booking.name}</td>
                    <td className="p-3">{booking.quantity}</td>
                    <td className="p-3">
                      {parseDateDDMMYYYY(booking.created_at)}
                    </td>
                    <td className="p-3">
                      {booking.is_messout}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        modalHeading={modalHeading}
        modalText={modalText}
      />
      <ConfirmDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={update_event_price}
      />
    </div>
  );
};

export default Mess_Special_Event_BookingList;
