
import { useState, useContext } from "react"
import { UserContext } from "../Contexts/UserContext"
import ConfirmDialog from '../components/ConfirmDialog'
import * as FileSaver from 'file-saver';

import 'jspdf-autotable';
import XLSX from 'sheetjs-style';
import DialogBoxWithInputField from "./DialogBoxWithInputField";

function GeneratedMessBillList({ calculatedMessBillList }) {
    console.log(calculatedMessBillList)

    const { user, setLoading } = useContext(UserContext)

    const [open1, setOpen1] = useState(false);
    const [modalHeading, setModalHeading] = useState("")
    const [modalText, setModalText] = useState("")


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';

    // const downloadExcel = async () => {
    //     const columns = ["hostel_admission_no", "name", "attendance", "mess_charge", "mess_extras", "monthly_amount", "egrants", "to_pay", "dues", "fine", "amount"];

    //     // const header = ["Hostel Admission No", "Name", "Attendance", "Mess Charge", "Mess Extras", "Total", "E grants", "BIll to Be Paid", "Dues", "Fine", "Total Amount to be paid"];

    //     // const rows = calculatedMessBillList.map((row, index) => {
    //     //     return columns.map((column, i) => {
    //     //         // if (column == "egrants") {
    //     //         //     return row.egrants ? Math.min(3500, row["monthly_amount"]-row["mess_extras"]) : 0
    //     //         // }

    //     //         if (column == "to_pay")
    //     //             return row.egrants ? row["monthly_amount"] - row["egrants"]-row["mess_extras"] : row["monthly_amount"]
    //     //         if (column == "amount")
    //     //             return row.egrants ? row["amount"] - Math.min(3500, row["monthly_amount"]-row["mess_extras"]): row["amount"]
    //     //         return row[column]
    //     //     })
    //     // });
    //     const datas = [ ...calculatedMessBillList]
    //     const ws = XLSX.utils.json_to_sheet(datas);
    //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const data = new Blob([excelBuffer], { type: fileType });
    //     FileSaver.saveAs(data, `Mess Bill January LH .xlsx`)

    // }
    const downloadExcel = async () => {
        const columns = ["hostel_admission_no", "name", "attendance", "mess_charge", "mess_extras", "monthly_amount", "egrants", "to_pay", "dues", "fine", "amount","non_veg_charge"];

        const header = ["Hostel Admission No", "Name", "Attendance", "Mess Charge", "Mess Extras", "Total", "E grants", "BIll to Be Paid", "Dues", "Fine", "Total Amount to be paid","Special Charge"];

        const rows = calculatedMessBillList.map((row, index) => {
            return columns.map((column, i) => {
                if (column == "egrants") {
                    return row.egrants ? Math.min(3500, row["monthly_amount"]-row["mess_extras"]) : 0
                }

                if (column == "to_pay")
                    return row.egrants ? row["monthly_amount"] - Math.min(3500, row["monthly_amount"]-row["mess_extras"]) : row["monthly_amount"]
                if (column == "amount")
                    return row.egrants ? row["amount"] - Math.min(3500, row["monthly_amount"]-row["mess_extras"]): row["amount"]
                return row[column]
            })
        });
        const datas=[header,...rows]
        const ws = XLSX.utils.json_to_sheet(datas);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Mess Bill January LH .xlsx`)

    }

    const downloadMessExtrasExcel = async () => {
        const columns = ["hostel_admission_no", "name", "mess_extras"];

        const header = ["Hostel Admission No", "Name", "Mess Extras"];

        const rows = calculatedMessBillList.map((row, index) => {
            return columns.map((column, i) => {
                return row[column]
            })
        });
        const datas = [header, ...rows]
        const ws = XLSX.utils.json_to_sheet(datas);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Mess Extras February LH .xlsx`)

    }
    return (
        <>
            <div className='w-12/12 overflow-x-scroll'>
                <h1 className="font-semibold text-black text-lg mb-3 mt-3"></h1>
                <h1 className="font-bold text-black text-xl mb-3 mt-3">Mess Bill List </h1>

                <div className="w-full mb-5 flex items-end justify-end mt-5">
                    <button
                        onClick={() => {
                            downloadExcel()
                        }}
                        type="button"
                        className="ml-auto p-3 bg-stone-800 text-white rounded-xl"
                    >
                        Download PDF
                    </button>
                </div>
                <table className='w-full relative table-auto'>
                    <tr className='rounded-xl p-3 bg-primary text-center'>
                        <th className='p-3'>Sl.No</th>
                        <th className='p-3'>Hostel Ad.No</th>
                        <th className='p-3'>Name</th>
                        <th className='p-3'>Attendance</th>
                        <th className='p-3'>Mess Charge</th>
                        <th className='p-3'>Bakery Items</th>
                        <th className='p-3'>Add on Items</th>
                        <th className='p-3'>Special Charge</th>
                        <th className='p-3'>Additional Charges</th>
                        <th className='p-3'>Total</th>
                        <th className='p-3'>E Grant</th>
                        <th className='p-3'>To be Paid</th>
                        <th className='p-3'>Dues</th>
                        <th className='p-3'>FINE</th>
                        <th className='p-3'>Total to be Paid</th>
                    </tr>
                    {calculatedMessBillList.map((bill, index) => {
                        let egrants = 0;
                        // if (bill.egrants) {
                        //     egrants = Math.min(3500, bill.monthly_amount-bill.mess_extras)
                        // }


                        return (
                            <tr
                                key={index}
                                className={'border-b text-center border-slate-200 border-solid hover:bg-gray-300'}
                            >
                                <td className='p-3'>{index + 1}</td>
                                <td className='p-3'>{bill.hostel_admission_no}</td>
                                <td className='p-3'>{bill.name}</td>
                                <td className='p-3'>{bill.attendance}</td>
                                <td className='p-3'>{bill.mess_charge}</td>
                                <td className='p-3'>{bill.mess_extras}</td>
                                <td className='p-3'>{bill.add_on_item}</td>
                                <td className='p-3'>{bill.non_veg_charge}</td>
                                <td className='p-3'>{bill.special_charges}</td>
                                <td className='p-3'>{bill.monthly_amount}</td>
                                <td className='p-3'>{bill.egrants}</td>
                                <td className='p-3'>{bill.monthly_amount - bill.egrants}</td>
                                <td className='p-3'>{bill.dues}</td>
                                <td className='p-3'>{bill.fine}</td>
                                <td className='p-3'>{bill.amount - bill.egrants}</td>

                            </tr>
                        )
                    })}
                </table>
            </div>
            <ConfirmDialog open={open1} setOpen={setOpen1} modalHeading={modalHeading} modalText={modalText} confirmFunction={() => { }} />
        </>
    )
}


export default GeneratedMessBillList