import React, { useContext, useEffect, useState } from 'react';
import DateConverter from '../../Utils/DateConverter';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../baseUrl';
import { UserContext } from '../../Contexts/UserContext';
import AlertDialog from '../../components/AlertDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
function HostelOfficeUpdateTransactionPage() {

    const { id } = useParams()
    const { setLoading, user } = useContext(UserContext)


    const [messBillDetails, setMessBillDetails] = useState({})
    const [additionalDetails, setAdditionalDetails] = useState(null)
    const [messExempt, setMessExempt] = useState(false)
    const [specialCharges, setSpecialCharges] = useState(null)
    const [amount, setAmount] = useState();
    const [transactionID, setTransactionID] = useState();
    const [date, setDate] = useState(null)
    const [paymentMode, setPaymentMode] = useState(null)
    const [transactionHistory, setTransactionHistory] = useState([])
    const [nonVegCharges, setNonVegCharges] = useState(null)

    const paymentModesMap = {
        0: "Bank",
        1: "Hostel Office"
    }

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [modalHeading, setModalHeading] = useState("");
    const [modalText, setModalText] = useState("");

    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}/inmate/get-mess-bill-details?bill_id=${id}`).then((res) => {
            setMessBillDetails(res.data.data)
            setAdditionalDetails(res.data.additionalDetails)
            setSpecialCharges(res.data.special_charges)
            setMessExempt(res.data.mess_exempt)
            setNonVegCharges(res.data.non_veg_charges)

        }).catch((err) => {

            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}/inmate/get-updated-fine?id=${id}&&date=${date}`).then((res) => {
            let data = messBillDetails;
            data.fine = res.data.data.fine
            console.log(data)
            setMessBillDetails(data)
        }).catch((err) => {

        }).finally(() => {
            setLoading(false)
        })
    }, [date])

    const validateAmount = () => {
        setModalHeading("Are you Sure !")
        setModalText("The Amount given is  " + amount + " .Please Ensure that this is correct . Do you want to continue ?.")
        setOpen3(true);
    }
    const validateTransDetails = (details) => {
        setModalHeading("Are you Sure !")
        if (details.length == 0) {
            setModalText("The Transaction ID given is : " + transactionID + " .Please Ensure that this is correct . Do you want to continue ?.")

        }
        else {
            setModalText("The Transaction ID given is : " + transactionID + " There is already " + details.length + " Transaction with the same Transaction ID . Do you want to continue ?.")
        }
        setOpen2(true);

    }

    const finalSubmit = () => {
        const total_amount = parseFloat(messBillDetails.remaining_amount) + parseFloat(messBillDetails.fine)
        if (amount > parseFloat(messBillDetails.remaining_amount) + parseFloat(messBillDetails.fine)) {
            setModalHeading("Are you Sure !")
            setModalText("The Amount given " + amount + " is more than the Amount to be paid " + total_amount + ". The extra amount will be maintained as your credit. Do you want to continue ?.")
            setOpen4(true);
            return
        }
        if (amount < parseFloat(messBillDetails.remaining_amount) + parseFloat(messBillDetails.fine)) {
            setModalHeading("Are you Sure !")
            setModalText("The Amount given  " + amount + "  is less than the Amount to be paid " + total_amount + ". The remaining amount will be maintained as your due from this bill. Do you want to continue ?.")
            setOpen4(true);
            return
        }
        setModalHeading("Are you sure ? ")
        setModalText("Before Proceeding please ensure that the payment details you have provided is correct . After submission no changes will be possible. Do you want to continue ?.")
        setOpen4(true)
    }


    const checkTransactionID = () => {
        setLoading(true)
        axios.get(`${baseUrl}/clerk-a3/get-bill-previous-transactions?transaction_id=${transactionID}&&mess_bill_id=${id}`).then((res) => {
            if (res.data.data.length == 0) {
                validateTransDetails(res.data.data)
            }
            else {
                setTransactionHistory(res.data.data)
            }



        }).catch((err) => {
            setModalHeading("Server Error ! ")
            setModalText("The server is Facing some issues . Please try again later . if the issue persist try contacting the developer !")
            setOpen1(true)

        }).finally(() => {
            setLoading(false)
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (amount <= 0 || transactionID.length < 1 || (paymentMode != 0 && paymentMode != 1)) {
            setModalHeading("Invalid Data !")
            setModalText("Please Correct The Details Provided And Try Again ")
            setOpen1(true)
            return;
        }

        checkTransactionID()

    }

    const updatePaymentDetails = () => {
        setLoading(true);

        axios.post(`${baseUrl}/clerk-a3/update-transaction-details`, { id: user.user_id, bill_id: id, amount: amount, transaction_id: transactionID, date: date, user_id: user.user_id, payment_mode: paymentMode }).then((res) => {
            setAmount(0)
            setTransactionID('')
            setDate('')
            setModalHeading("Your Request Completed !")
            setModalText("Your Payment Status Have been forwarded . It will be reflected in your mess bill once the management update it .")
            setOpen1(true)
            window.location = '/'
        }).catch((err) => {
            if (err.response.status == 406) {
                setModalHeading("Invalid Request !")
                setModalText("Amount Provided should be less than the remaininin amount to be paid . Please change it and proceed again")
                setOpen1(true)
            }
            else if (err.response.status == 401) {
                setModalHeading("Failed to update payment !")
                setModalText(err.response.data.msg)
                setOpen1(true)
            }
            else {
                setModalHeading("Invalid Request !")
                setModalText("Could not Complete Your request . Please Ensure that you type the payment details correctly  .")
                setOpen1(true)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className="w-full m-3 p-6 bg-white rounded-lg shadow-md">
            <div className='flex justify-center items-center'>
                <h2 className="text-3xl font-semibold mb-4">Update Payment Details</h2>
            </div>
            <div className="max-w-4xl mt-10 grid md:grid-cols-2 gap-4">

                <div className="col-span-1">
                    <div className="  border-dashed border-4 border-slate-200 rounded-lg z-10">
                        <div className="mb-4 p-3 flex flex-wrap">
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Bill Month:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.month + " - " + messBillDetails.year}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Bill Published Date:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && DateConverter.parseDateDDMMYYYY(messBillDetails.bill_issued_date)}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Attendance:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.attendance}</p>
                            {additionalDetails && additionalDetails.map((details, index) => (
                                <>
                                    <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.title}:</p>
                                    <p className="font-semibold text-xl m-2 flex-grow">{messExempt ? details.establishment_charge ? 0 : details.amount : details.amount}</p>
                                </>
                            ))}
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Charge</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.mess_charge}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Extras:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.mess_extras}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Add on Items:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.add_on_item}</p>
                            {
                                specialCharges && specialCharges.map((details, index) => (
                                    <>
                                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.title}:</p>
                                        <p className="font-semibold text-xl m-2 flex-grow">{details.amount}</p>
                                    </>
                                ))
                            }
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Total Special Charges:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && Math.round(messBillDetails.non_veg_charges * 100) / 100}</p>

                            {
                                nonVegCharges && nonVegCharges.map((details, index) => (
                                    <>
                                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.item}:</p>
                                        <p className="font-semibold text-xl m-2 flex-grow">{Math.round(details.charge * 100) / 100}</p>
                                    </>
                                ))
                            }
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Sum Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.sum_amount}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Egrants:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.egrants}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Total Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.total_amount}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Remaining Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.remaining_amount}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Fine:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.fine}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Amount To Pay:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && parseFloat(messBillDetails.remaining_amount) + parseFloat(messBillDetails.fine)}</p>

                        </div>
                    </div>
                </div>

                <div className="col-span-1 sm:ml-10 w-full">
                    <div>
                        <p className='text-2xl font-semibold text-slate-400 mt-10'>Payment</p>
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                            <label className='font-semibold' htmlFor="">Paid Date :</label>{" "}
                            <input
                                required
                                max={DateConverter.getToday()}
                                placeholder='Date'
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value)
                                }}
                                type="date"
                                className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 w-100 gap-4 m-5 mb-3">
                            <label className='font-semibold' htmlFor="">Payment Mode :</label>{" "}
                            <div className="flex items-center">
                                <input
                                    value={0}
                                    type="radio"
                                    name="paymentMethod"
                                    onChange={(e) => {
                                        setPaymentMode(e.target.value)
                                    }}
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                />
                                <label className="ml-2 block text-md font-bold leading-5 text-gray-700">
                                    Bank
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    value={1}
                                    type="radio"
                                    name="paymentMethod"
                                    onChange={(e) => {
                                        setPaymentMode(e.target.value)
                                    }}
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                />
                                <label className="ml-2 block text-md font-bold leading-5 text-gray-700">
                                    Hostel Office
                                </label>
                            </div>
                        </div>
                        {
                            (paymentMode == 1 || paymentMode == 0) && date != null && <div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                                    <label className='font-semibold' htmlFor="">Amount Paid (R.S) :</label>{" "}
                                    <input
                                        required
                                        placeholder='Enter the amount'
                                        type="number"
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value)
                                        }}
                                        className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                                    />
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                                    <label className='font-semibold' htmlFor="">{paymentMode == 0 ? "Transaction ID :" : "Receipt Number :"}</label>{" "}
                                    <input
                                        required
                                        placeholder='Transaction ID'
                                        type="text"
                                        value={transactionID}
                                        onChange={(e) => {
                                            setTransactionHistory([])
                                            setTransactionID(e.target.value)
                                        }}
                                        className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                                    />
                                </div>
                            </div>
                        }



                        <button
                            type='submit'
                            className="w-full m-2  bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded shadow focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </form>
                    {
                        transactionHistory && transactionHistory.length > 0 && <div className="w-100  border-dashed border-4 border-slate-200 rounded-lg z-10">
                            <p className='font-bold text-red-400 m-2'>These Are the Transaction with same Transaction ID </p>
                            <form >

                                <table className="w-full relative table-auto">
                                    <tr className="rounded-xl p-3 bg-primary text-center">
                                        <th className="p-2">Hostel Admn No.</th>
                                        <th className="p-2">Transaction ID</th>
                                        <th className="p-2">Amount Paid</th>
                                        <th className="p-2">Paid Date</th>
                                    </tr>

                                    {
                                        transactionHistory.map((tr, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className={
                                                        "border-b text-center border-slate-200 border-solid hover:bg-gray-300"
                                                    }
                                                >
                                                    <td className="p-3 text-sm">{tr.hostel_admission_no}</td>
                                                    <td className="p-3 text-sm">{tr.transaction_id}</td>
                                                    <td className="p-3 text-sm">{tr.amount}</td>
                                                    <td className="p-3 text-sm">{DateConverter.parseDateDDMMYYYY(tr.paid_date)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                                <button
                                    onClick={() => {
                                        validateTransDetails(transactionHistory)
                                    }}
                                    type='button'
                                    className="w-full m-2  bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded shadow focus:outline-none focus:shadow-outline"
                                >
                                    Continue
                                </button>

                            </form>
                        </div>
                    }


                </div>
                <AlertDialog
                    open={open1}
                    setOpen={setOpen1}
                    modalHeading={modalHeading}
                    modalText={modalText}
                />
                <ConfirmDialog
                    open={open2}
                    setOpen={setOpen2}
                    modalHeading={modalHeading}
                    modalText={modalText}
                    confirmFunction={validateAmount}
                />
                <ConfirmDialog
                    open={open3}
                    setOpen={setOpen3}
                    modalHeading={modalHeading}
                    modalText={modalText}
                    confirmFunction={finalSubmit}
                />
                <ConfirmDialog
                    open={open4}
                    setOpen={setOpen4}
                    modalHeading={modalHeading}
                    modalText={modalText}
                    confirmFunction={updatePaymentDetails}
                />
            </div>
            <div className="bg-yellow-200 text-yellow-800 p-4 mt-4 rounded-md">
                <p className="font-semibold mb-2">Warning:</p>
                <ul className="list-disc ml-6">
                    <li> Mess bill is generated using the software from 03/2024 .However due amounts and Bill Dates are copied from the manually generated bills. Since individual details of such bills are not copied they are shown as zero here </li>
                    <li>Make sure you have typed the transaction ID correct .</li>
                    <li>Ensure The date provided is correct .</li>
                    <li>Double-check the accuracy of payment details.</li>

                </ul>
            </div>

        </div>
    );
}

export default HostelOfficeUpdateTransactionPage;
