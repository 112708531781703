import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Button, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { baseUrl } from '../../../baseUrl';

const { RangePicker } = DatePicker;

const EditNonVegItem = ({ itemId, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(`${baseUrl}/inmate/non-veg-items/${itemId}`);
        setItem(response.data);
        form.setFieldsValue({
          item: response.data.item,
          month: dayjs(`${response.data.year}-${response.data.month.toString().padStart(2, '0')}`),
          bookingPeriod: [dayjs(response.data.booking_start), dayjs(response.data.booking_end)],
          datesServed: response.data.dates_served.map(date => dayjs(date)),
        });
      } catch (error) {
        console.error('Error fetching item:', error);
        message.error('Failed to fetch item details');
      }
    };

    if (itemId) {
      fetchItem();
    }
  }, [itemId, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        item: values.item,
        month: values.month.month() + 1,
        year: values.month.year(),
        bookingStart: values.bookingPeriod[0].format('YYYY-MM-DD'),
        bookingEnd: values.bookingPeriod[1].format('YYYY-MM-DD'),
        datesServed: values.datesServed.map(date => date.format('YYYY-MM-DD')),
      };

      await axios.put(`/api/non-veg-items/${itemId}`, payload);
      message.success('Item updated successfully');
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error updating item:', error);
      message.error('Failed to update item');
    } finally {
      setLoading(false);
    }
  };

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="item" label="Item Name" rules={[{ required: true, message: 'Please enter the item name' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="month" label="Month & Year" rules={[{ required: true, message: 'Please select the month and year' }]}>
        <DatePicker picker="month" />
      </Form.Item>

      <Form.Item name="bookingPeriod" label="Booking Period" rules={[{ required: true, message: 'Please select the booking period' }]}>
        <RangePicker />
      </Form.Item>

      <Form.Item name="datesServed" label="Dates Served" rules={[{ required: true, message: 'Please select the dates served' }]}>
        <DatePicker.Multiple 
          maxTagCount="responsive"
          disabledDate={(current) => {
            const monthStart = form.getFieldValue('month')?.startOf('month');
            const monthEnd = form.getFieldValue('month')?.endOf('month');
            return current && (current < monthStart || current > monthEnd);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update Item
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditNonVegItem;