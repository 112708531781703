import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Card, Typography, message } from 'antd';
import { baseUrl } from '../../../baseUrl';
// import { Button, Input, } from 'antd';

const { Title, Text } = Typography;

const VerifyCertificate = () => {
  const [certificateNo, setCertificateNo] = useState('');
  const [verificationResult, setVerificationResult] = useState(null);

  const handleVerify = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inmate/verifyCertificate/${certificateNo}`);
      setVerificationResult(response.data);
    } catch (error) {
      message.error('Failed to verify certificate');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Verify Inmate Certificate</h2>
      <Input
        placeholder="Enter Certificate Number"
        value={certificateNo}
        onChange={(e) => setCertificateNo(e.target.value)}
        className="mb-4"
      />
      <Button onClick={handleVerify} type="primary" className="w-full mb-4">
        Verify Certificate
      </Button>
      {verificationResult && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <p>Valid: {verificationResult.valid ? 'Yes' : 'Not Valid. CET Hostel office has not issued such a certificate. '}</p>
          {verificationResult.valid && (
            // <>
            //   <p>Admission No: {verificationResult.admissionNo}</p>
            //   <p>Name: {verificationResult.name}</p>
            //   <p>Issue Date: {verificationResult.issueDate}</p>
            // </>
            <Card className="mt-4">
              <Title level={4}>Certificate Details</Title>
              <div className="flex flex-col space-y-2">
                <div>
                  <Text strong>Admission Number:</Text> {verificationResult.admissionNo}
                </div>
                <div>
                  <Text strong>Name:</Text> {verificationResult.name}
                </div>
                <div>
                  <Text strong>Purpose:</Text> {verificationResult.purpose}
                </div>
                <div>
                  <Text strong>Issue Date:</Text> {new Date(verificationResult.issueDate).toLocaleDateString()}
                </div>
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default VerifyCertificate;