import React, { useState, useEffect } from 'react';
import { Table, Input, Button, DatePicker as DatePickerAntd } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import dayjs from 'dayjs';
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import CalendarModal from './CalendarModal';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import EditItem from './EditItem';

const { RangePicker } = DatePickerAntd;

const AddNonVegItem = () => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [selection, setSelection] = useState('Add');
  const [servedOn, setServedOn] = useState([]);
  const [bookingStart, setBookingStart] = useState("");
  const [bookingEnd, setBookingEnd] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM'));
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [open2, setOpen2] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [activeTab, setActiveTab] = useState("view"); // "add" or "view"

  useEffect(() => {
    fetchItems();
  }, [selectedMonth]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inmate/non-veg-items`, {
        params: { selectedMonth, hostel: "LH" }
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const addItem = async () => {
    if (!newItem.trim()) return;
    try {
      const response = await axios.post(`${baseUrl}/inmate/non-veg-items`, {
        item: newItem,
        selectedMonth:selectedMonth,
        servedOn:servedOn,
        bookingEnd:bookingEnd,
        bookingStart:bookingStart,
        hostel: "LH"
      });
      setItems([...items, response.data]);
      setNewItem('');
      setModalHeading("Success");
      setModalText(`Item ${newItem} added successfully`);
      setOpen1(true);
    } catch (error) {
      setModalHeading("Error");
      setModalText("Failed to add item");
      setOpen1(true);
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setModalHeading("Confirm Addition");
    setModalText(`Add ${newItem} with booking period from ${bookingStart} to ${bookingEnd}?`);
    setOpen2(true);
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      width: '30%',
    },
    {
      title: 'Booking Period',
      dataIndex: 'booking_period',
      width: '35%',
      render: (_, record) => (
        <div className="space-y-1">
          <div className="text-sm">Start: {dayjs(record.booking_start).format("DD-MM-YYYY")}</div>
          <div className="text-sm">End: {dayjs(record.booking_end).format("DD-MM-YYYY")}</div>
        </div>
      ),
    },
    {
      title: 'Dates Served',
      dataIndex: 'dates_served',
      width: '35%',
      render: (_, record) => (
        <CalendarModal dates={record.dates_served.map(date => dayjs(date).format('YYYY-MM-DD'))} />
      ),
    },
  ];

  return (
    <div className="w-11/12 ">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="">
          <div className="flex gap-4 mb-6">
            <button
              onClick={() => setActiveTab("view")}
              className={`px-4 py-2 rounded-lg ${activeTab === "view"
                ? "bg-stone-800 text-white"
                : "bg-gray-200 text-gray-700"
                }`}
            >
              View Events
            </button>
            <button
              onClick={() => setActiveTab("add")}
              className={`px-4 py-2 rounded-lg ${activeTab === "add"
                ? "bg-stone-800 text-white"
                : "bg-gray-200 text-gray-700"
                }`}
            >
              Add New Event
            </button>
          </div>

          {activeTab == 'add' && (
            <div className="space-y-6">
              <h2 className="text-xl font-semibold text-gray-900">Enter Details</h2>
              <form onSubmit={handleConfirm} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Month & Year
                    </label>
                    <DatePickerAntd
                      picker="month"
                      onChange={(date, dateString) => setSelectedMonth(dateString)}
                      className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                      minDate={dayjs().add(1, 'month')}
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Booking Period
                    </label>
                    <DatePicker
                      minDate={new DateObject().add(1, 'day')}
                      maxDate={new DateObject(selectedMonth).subtract(1, 'month').toLastOfMonth()}
                      inputClass="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                      onChange={(date, dateStrings) => {
                        if (dateStrings) {
                          setBookingStart(dateStrings.validatedValue[0]);
                          setBookingEnd(dateStrings.validatedValue[1]);
                        }
                      }}
                      range
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Serving Dates
                    </label>
                    <DatePicker
                      multiple
                      onChange={(date,dateString) =>  setServedOn(dateString.validatedValue)                      }
                      minDate={new DateObject(selectedMonth).toFirstOfMonth()}
                      maxDate={new DateObject(selectedMonth).toLastOfMonth()}
                      sort
                      format="MM/DD/YYYY"
                      calendarPosition="bottom-center"
                      plugins={[<DatePanel />]}
                      inputClass="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Item Name
                    </label>
                    <input
                      required
                      value={newItem}
                      onChange={(e) => setNewItem(e.target.value)}
                      className="w-full py-2 px-3 rounded-xl ring-2 ring-slate-300 focus:outline-none"
                      placeholder="Enter item name"
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                  >
                    Add Item
                  </button>
                </div>
              </form>
            </div>
          )}
          {activeTab == 'view' && (
            <div className='w-full'>
              <EditItem />
            </div>
          )}
        </div>
      </div>

      <AlertDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
      />

      <ConfirmDialog
        open={open2}
        setOpen={setOpen2}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={addItem}
      />
    </div>
  );
};

export default AddNonVegItem;