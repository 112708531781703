
import React, { useState, useEffect, useContext } from 'react';
import { Table, Select, Button, Space, message, Modal, Form, Input } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import dayjs from 'dayjs';
import { UserContext } from '../../../Contexts/UserContext';
import { motion } from "framer-motion";

const { Option } = Select;

const NonVegItemsBookingHistory = () => {
    const [bookings, setBookings] = useState([]);
    const [items, setItems] = useState([]);
    const { setLoading, user } = useContext(UserContext);

    // const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingBookingId, setEditingBookingId] = useState(null);

    useEffect(() => {
        fetchBookings();
        fetchItems();
    }, []);

    const updateQuantity = (productId, newQuantity) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: parseInt(newQuantity),
        }));
    };
    const fetchBookings = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/inmate/non-veg-bookings`);
            setBookings(response.data);
        } catch (error) {
            console.error('Error fetching bookings:', error);
            message.error('Failed to fetch bookings');
        } finally {
            setLoading(false);
        }
    };

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${baseUrl}/inmate/non-veg-items`);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
            message.error('Failed to fetch items');
        }
    };

    const handleAddBooking = () => {
        setEditingBookingId(null);
        form.resetFields();
        setModalVisible(true);
    };

    const handleEditBooking = (booking) => {
        setEditingBookingId(booking.booking_id);
        form.setFieldsValue({
            hostel_admission_no: booking.hostel_admission_no,
            item_id: booking.item_id,
            month: booking.month,
            year: booking.year,
        });
        setModalVisible(true);
    };

    const handleDeleteBooking = async (bookingId) => {
        try {
            await axios.delete(`${baseUrl}/inmate/non-veg-bookings/${bookingId}`);
            message.success('Booking deleted successfully');
            fetchBookings();
        } catch (error) {
            console.error('Error deleting booking:', error);
            message.error('Failed to delete booking');
        }
    };

    const handleModalOk = () => {
        form.validateFields().then(async (values) => {
            try {
                if (editingBookingId) {
                    await axios.put(`${baseUrl}/inmate/non-veg-bookings/${editingBookingId}`, values);
                    message.success('Booking updated successfully');
                } else {
                    await axios.post(`${baseUrl}/inmate/non-veg-bookings`, values);
                    message.success('Booking added successfully');
                }
                setModalVisible(false);
                fetchBookings();
            } catch (error) {
                console.error('Error saving booking:', error);
                message.error('Failed to save booking');
            }
        });
    };
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const columns = [
        {
            title: 'Booking ID',
            dataIndex: 'booking_id',
            key: 'booking_id',
        },
        {
            title: 'Item',
            dataIndex: 'item_id',
            key: 'item_id',
            render: (itemId) => items.find(item => item.item_id === itemId)?.item || 'Unknown',
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            render: (text) =>  months[text] 


        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
    ];
    const [status, set_status] = useState(0);

    const [quantities, setQuantities] = useState({});


    return (
        <div className="p-4 w-11/12">
            <h2 className="text-2xl font-bold mb-4">Special Items Booking History</h2>
            <Table
                columns={columns}
                dataSource={bookings}
                rowKey="booking_id"
            />
            <Modal
                title={editingBookingId ? "Edit Booking" : "Add Booking"}
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={() => setModalVisible(false)}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="hostel_admission_no"
                        label="Hostel Admission No"
                        rules={[{ required: true, message: 'Please input the hostel admission number!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="item_id"
                        label="Item"
                        rules={[{ required: true, message: 'Please select an item!' }]}
                    >
                        <Select>
                            {items.map(item => (
                                <Option key={item.item_id} value={item.item_id}>{item.item}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="month"
                        label="Month"
                        rules={[{ required: true, message: 'Please input the month!' }]}
                    >
                        <Input type="number" min={1} max={12} />
                    </Form.Item>
                    <Form.Item
                        name="year"
                        label="Year"
                        rules={[{ required: true, message: 'Please input the year!' }]}
                    >
                        <Input type="number" min={2000} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default NonVegItemsBookingHistory;
