import React, { useState, useEffect, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import editSvg from '../../icons/edit.svg'
import userSvg from '../../icons/user.svg'
import { UserContext } from '../../Contexts/UserContext'
import { Layout, Menu, theme } from 'antd';


function InmateHome() {

    const { user } = useContext(UserContext)
    const [links, setLinks] = useState([])

    console.log("Inmate roles: ", user.roles)

    useEffect(() => {
        var subLinks = []
        if (user.roles.includes("md")) {
            subLinks.push({
                title: "Mess Director",
                to: "messdirector",
                icon: <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>,
            })
        }

        if (user.roles.includes("ms")) {
            subLinks.push({
                title: "Mess Secretary",
                to: "messdirector",
                icon: <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>,
            })
        }

        setLinks([
            {
                title: "Mess",
                to: "/inmate/mess",
                icon: editSvg,
            },
            {
                title: "Certificates",
                to: "/inmate/certificates",
                icon: editSvg,
            },
            {
                title: "Mess Bill",
                to: "/inmate/mess-bill",
                icon: editSvg,
            },
            {
                title: "Bakery Items",
                to: "/inmate/mess-special-events",
                icon: editSvg,
            },
            {
                title: "Add On Items",
                to: "/inmate/add-on-items",
                icon: editSvg,
            },
            {
                title: "Special Items",
                to: "/inmate/non-veg-items",
                icon: editSvg,
            },
            // {
            //     title:"Calculate Mess Bill",
            //     to:"/inmate/hostel",
            //     icon: editSvg,
            // },
            {
                title: "Roles",
                icon: userSvg,
                subLinkOpen: true,
                subLinks: subLinks
            },
        ])
    }, [user.roles])



    return (
        <Layout hasSider className='h-screen'>
            <SideBar myLinks={links} myActiveIndex={0} myOpenedIndex={0} />

            <Outlet />
        </Layout>

    )
}

export default InmateHome