import React from 'react';

const SearchComponent = ({ onSearch }) => {
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    onSearch(searchTerm);
  };

  return (
    <div className='w-4/5 p-2 border rounded'>
      <input
        className="w-4/5 p-2 border rounded"
        type="text"
        placeholder="Search by name or hostel admission number"
        onChange={handleSearch}
      />
    </div>
  );
};

export default SearchComponent;