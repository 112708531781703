import axios from 'axios'
import React, { useState, useContext } from 'react'
import { UserContext } from '../../../Contexts/UserContext'
import AlertDialog from '../../../components/AlertDialog'
import { baseUrl } from '../../../baseUrl'



function PromotionWD() {
    const [programme, setProgramme] = useState("UG")
    const [category, setCategory] = useState("promote")
    const [yearState, setYearState] = useState("1")
    console.log('yearState', yearState)
    const years = [
        "First Year",
        "Second Year",
        "Third Year",
        "Fourth Year",
        ""
    ]
    const { setLoading } = useContext(UserContext);

    const [open1, setOpen1] = useState("");
    const [open2, setOpen2] = useState("");
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");

    const handleSubmit = async () => {
        setLoading(true)
        console.log("hiii")
        await axios.post(`${baseUrl}/warden/promote-student`, { programme: programme, year: yearState, category: category }).then(res => {
            setLoading(false)
            setModalHeading("Request Completed ");
            setModalText("User Added Successfully .");
            setOpen1(true);
            setLoading(false)


        })
    }
    return (
        <div>
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
                <label htmlFor="">Category :</label>{" "}
                <select
                    name="category"
                    onChange={(e) => setCategory(e.target.value)}
                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                    required
                >
                    <option value={"promote"}>Promote</option>
                    <option value={"demote"}>Demote</option>
                </select>
            </div>
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
                <label htmlFor="">Programme :</label>{" "}
                <select
                    name="programme"
                    onChange={(e) => setProgramme(e.target.value)}
                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                    required
                >
                    <option value={"UG"}>UG</option>
                    <option value={"PG"}>PG</option>
                    <option value={"Phd"}>Phd</option>
                </select>
            </div>
            <div className="grid grid-cols-2 w-100 gap-4 mb-3">
                <label htmlFor="">Year :</label>{" "}
                <select
                    name="year"
                    onChange={(e) => setYearState(e.target.value)}
                    className="p-3 ring-slate-200 ring-2 rounded-xl outline-none"
                    required
                >
                    <option value={"1"}>First Year</option>
                    <option value={"2"}>Second Year</option>
                    <option value={"3"}>Third Year</option>
                    <option value={"4"}>Fourth Year</option>
                </select>
            </div>
            {category === "promote" && yearState != "4" && <p>{`${years[+yearState - 1]} wil be promotted to ${years[+yearState]}`}</p>}
            {category === "demote" && yearState != "1" && <p>{`${years[+yearState - 1]} wil be demoted to ${years[+yearState - 2]}`}</p>}
            <div className="w-full flex items-end justify-end mt-5">
                <button className="ml-auto p-3 bg-stone-800 text-white rounded-xl" onClick={handleSubmit}>Submit</button>
            </div>
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
        </div>
    )
}

export default PromotionWD