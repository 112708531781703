import React, { useContext, useState } from 'react';
import { jsPDF } from "jspdf";
import { Download, FileText, Loader2 } from 'lucide-react';

// You'll need to import the logo
import logo from '../../../icons/Cet_emblem.jpg';
import { baseUrl } from '../../../baseUrl';
import axios from 'axios';
import { UserContext } from '../../../Contexts/UserContext';
import dayjs from 'dayjs'



const CertificateDownloader = () => {

  const { user, setLoading } = useContext(UserContext);
  const [isGenerating, setIsGenerating] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [purposeInputs, setPurposeInputs] = useState({
    Expense: '',
    NonInmate: '',
    Inmate: ''
  });

  const generatePDF = async (certData) => {
    const doc = new jsPDF();
    let data;

    const hostelName = { "LH": "Ladies Hostels", "MH": "Men's Hostel" }
    const proNoun = { "LH": "She", "MH": "He" }
    const department = { 'CE': 'Civil Engineering', 'ME': 'Mechanical Enginnering', 'CS': 'Computer Science Engineering.' }

    const response = await axios.get(`${baseUrl}/inmate/get-inmate-deatils`, { params: { admissionNo: user.user_id } })
    if (certData.certificateType == 'Expense') {
      const res = await axios.get(`${baseUrl}/inmate/get-mouthly-expense`, { params: { admissionNo: user.user_id } })
      data = {
        date: dayjs().format('DD.MM.YYYY'),
        content: `Certified that ${user.name}  (College Ad. No. ${user.user_id}, Hostel Ad. No. ${response.data.hostel_admission_no}) is an inmate of ${hostelName[user.hostel]} attached to the College of Engineering, Trivandrum. ${proNoun[user.hostel]} is undergoing ${response.data.programme} studies under Dicipline  ${response.data.department}.`,
        expenditure: res.data,
        purpose: 'is issued on her request for the purpose of ' + purposeInputs.Expense,
      }
    } else if (certData.certificateType == 'NonInmate') {

      data = {
        date: dayjs().format('DD.MM.YYYY'),
        content: `Certified that ${user.name} (College Ad. No. ${user.user_id})  is undergoing ${response.data.programme} studies under Dicipline  ${response.data.department}. ${proNoun[user.hostel]} is a non-inmate of the ${hostelName[user.hostel]}.`,
        purpose: 'is issued on her request for the purpose of ' + purposeInputs.NonInmate,
      }
    } else if (certData.certificateType == 'Inmate') {

      data = {
        date: dayjs().format('DD.MM.YYYY'),
        content: `Certified that ${user.name} (College Ad. No. ${user.user_id}, Hostel Ad. No.  ${response.data.hostel_admission_no}) is under going ${response.data.programme} studies under Dicipline ${response.data.department}. ${proNoun[user.hostel]} is an inmate of the ${hostelName[user.hostel]}.`,
        purpose: 'is issued on her request for the purpose of ' + purposeInputs.Inmate,
      }
    }

    // Add font
    doc.addFont('times', 'Times', 'normal');
    doc.setFont('Times');

    // Add logo
    doc.addImage(logo, 'PNG', 10, 10, 30, 30);

    // Header
    doc.setFontSize(16);
    doc.text("HOSTEL OFFICE - COLLEGE OF ENGINEERING", 105, 25, { align: "center" });
    doc.text("THIRUVANANTHAPURAM - 16", 105, 33, { align: "center" });
    doc.setFontSize(12);
    doc.text("Ph: 0471- 2515689, e-mail Id warden@cet.ac.in", 105, 41, { align: "center" });

    // Certificate number and date
    console.log('certData.certificateType', certData.certificateType)
    const responseWarden = await axios.get(`${baseUrl}/warden/warden-name`)
    const warden = responseWarden.data.name;

    const res = await axios.get(`${baseUrl}/inmate/get-certificate-number`, { params: { certificateType: certData.certificateType, admissionNo: user.user_id, purposeInput: purposeInputs[certData.certificateType] } })
    console.log('res', res)
    const { certificateNumber } = res.data
    doc.text(`No. ${certificateNumber}`, 20, 55);
    doc.text(`Dated: ${data.date}`, 190, 55, { align: "right" });

    // Title
    doc.setFontSize(16);
    doc.text("CERTIFICATE", 105, 70, { align: "center" });

    // Content
    doc.setFontSize(12);
    const contentLines = doc.splitTextToSize(data.content, 160);
    doc.text(contentLines, 20, 85, { align: "justify", maxWidth: 175 });

    let yPos = 85 + (contentLines.length * 7);
    if (certData.certificateType == 'Expense') {

      // Expenditure details (if applicable)
      doc.text("The monthly expenditure for her stay in this Hostel is shown below.", 20, yPos);
      yPos += 10;

      const columnWidth = 80;
      doc.text("Rent", 20, yPos);
      doc.text(`- Rs. ${data.expenditure.rent}/-`, 20 + columnWidth, yPos);
      yPos += 7;

      doc.text("Establishment Charge", 20, yPos);
      doc.text(`- Rs. ${data.expenditure.establishment_charge ? data.expenditure.establishment_charge : '0'}/-`, 20 + columnWidth, yPos);
      yPos += 7;

      doc.text("Mess Rate", 20, yPos);
      doc.text(`- Rs. ${data.expenditure.mess_rate ? data.expenditure.mess_rate : '0'}/-`, 20 + columnWidth, yPos);
      yPos += 7;

      doc.text("Total", 20, yPos);
      doc.text(`- Rs. ${data.expenditure.total}/-`, 20 + columnWidth, yPos);
      yPos += 7;

      doc.text(`(${data.expenditure.totalInWords})`, 20, yPos);
      yPos += 10;

      doc.text(`One year- ${data.expenditure.total}×12`, 20, yPos);
      doc.text(`- Rs.${data.expenditure.yearly}/- (Approximately)`, 20 + columnWidth, yPos);
      yPos += 15;
    }

    // Purpose
    doc.text(`This certificate ${data.purpose}`, 20, yPos);

    // Signature
    doc.text(warden, 190, 220, { align: "right" });
    doc.text("WARDEN", 190, 230, { align: "right" });

    // To
    doc.text("To,", 20, 255);
    doc.text(user.name, 35, 265);

    doc.setDrawColor(255, 0, 0);
    doc.line(5, 275, 205, 275);
    doc.line(5, 276, 205, 276);


    doc.setFontSize(10);
    doc.text('This is a computer-generated document. No signature is requireds.', 50, 283);
    doc.text('You can check the validity of this certificate by visting https://cethostel.in/verify-cerificate.', 45, 290);

    return doc;
  };
  const certificates = [
    {
      id: 1,
      name: 'Monthly Expense ',
      description: 'Download a detailed breakdown of your monthly hostel expenses',
      certificateType: 'Expense',
      icon: FileText
    },
    // {
    //   id: 2,
    //   name: 'Non-Inmate ',
    //   description: 'Verify your non-resident status at the hostel',
    //   certificateType: 'NonInmate',
    //   icon: FileText
    // },
    {
      id: 3,
      name: 'Inmate ',
      description: 'Confirm your resident status at the hostel',
      certificateType: 'Inmate',
      icon: FileText
    }
  ];
  const handleDownload = async (cert) => {
    try {
      setIsGenerating(true);
      setActiveCard(cert.id);
      const doc = await generatePDF(cert);
      doc.save(`${cert.name}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert("There was an error generating the PDF. Please try again.");
    } finally {
      setIsGenerating(false);
      setActiveCard(null);
      setPurposeInputs((prevState) => ({
        ...prevState,
        [cert.certificateType]: ''
      }));
    }
  };
  const handlePurposeChange = (e, certificateType) => {
    setPurposeInputs((prevState) => ({
      ...prevState,
      [certificateType]: e.target.value
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl w-11/12">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Certificate Downloads</h1>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Generate and download official certificates for your hostel-related documentation needs
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {certificates.map((cert) => (
          <div key={cert.id}>
            {/* Existing card code remains the same */}
            {cert.certificateType !== 'NonInmate' && (
              <div className="mt-4">
                <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg mb-4">
                  <cert.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 mb-2">{cert.name}</h2>
                <p className="text-gray-600 mb-6 text-sm">{cert.description}</p>

                <input
                  type="text"
                  id={`purpose-${cert.id}`}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={purposeInputs[cert.certificateType]}
                  onChange={(e) => handlePurposeChange(e, cert.certificateType)}
                  placeholder="Enter the purpose"
                />
              </div>
            )}
            <button
              onClick={() => handleDownload(cert)}
              disabled={isGenerating || !purposeInputs[cert.certificateType]}
              className={`w-full flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 
              ${(isGenerating && activeCard === cert.id) || !purposeInputs[cert.certificateType]
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 text-white'
                }`}
            >
              {isGenerating && activeCard === cert.id ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>
                  <Download className="w-4 h-4 mr-2" />
                  Generate PDF
                </>
              )}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-8 text-center text-sm text-gray-500">
        <p>All certificates are digitally generated
          {/* and include a verification QR code. */}
        </p>
        <p>For any issues, please contact the hostel office.</p>
      </div>
    </div>
  );
};

export default CertificateDownloader;