import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import { UserContext } from '../../../Contexts/UserContext';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import DateConverter from '../../../Utils/DateConverter';
function EditMessBillPage() {

    const { id } = useParams()
    const { setLoading, user } = useContext(UserContext)


    const [messBillDetails, setMessBillDetails] = useState({})
    const [userDetails,setUserDetails]=useState({})
    const [additionalDetails, setAdditionalDetails] = useState(null)
    const [messExempt, setMessExempt] = useState(false)
    const [specialCharges, setSpecialCharges] = useState(null)
    const [editable, setEditabele] = useState(null)
    const [changed,setChanged]=useState(false)
    const [nonVegCharges, setNonVegCharges] = useState(null)



    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [modalText, setModalText] = useState("");

    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}/inmate/get-mess-bill-details?bill_id=${id}`).then((res) => {
            setMessBillDetails(res.data.data)
            setUserDetails(res.data.userDetails)
            setAdditionalDetails(res.data.additionalDetails)
            setSpecialCharges(res.data.special_charges)
            setMessExempt(res.data.mess_exempt)
            setNonVegCharges(res.data.non_veg_charges)

        }).catch((err) => {

        }).finally(() => {
            setChanged(false)
            setLoading(false)
        })
    }, [changed])







 


    const handleSubmit = (e) => {
        e.preventDefault();
        if (editable.name.length<1) {
            setModalHeading("Invalid Data !")
            setModalText("Please Select a Column to edit ")
            setOpen1(true)
            return;
        }
        setModalHeading("Are You Sure ?")
        setModalText("You are about to edit the column " + editable.name + " Do you want to continue ?")
        setOpen2(true)


    }

    const editMessBill=()=>{
        setLoading(true)
        const data={
            id:id,
            column_name:editable.name,
            special_charge:editable.special,
            prev_value:editable.prevValue,
            new_value:editable.value,


        }
        axios.post(`${baseUrl}/warden/edit-mess-bill`,data).then((res)=>{
            setChanged(true);
            setEditabele(null)
            setModalHeading("Request Success !")
            setModalText("The mess bill was edited successfully !")
            setOpen1(true)
        }).catch((err)=>{
            setModalHeading("Request Failed !")
            setModalText("Failed to edit mess bill")
            setOpen1(true)
        }).finally(()=>{
            setLoading(false)
        })
    }

    const updateValue = (newValue) => {
        setEditabele(prevObj => ({
          ...prevObj, 
          value: newValue
        }));
      };
    return (
        <div className="w-full m-3 p-6 bg-white rounded-lg shadow-md">
            <div className='flex justify-center items-center'>
                <h2 className="text-3xl font-semibold mb-4">Edit Mess Bill</h2>
            </div>
            <div className="max-w-4xl mt-10 grid md:grid-cols-2 gap-4">

                <div className="col-span-1">
                    <div className="  border-dashed border-4 border-slate-200 rounded-lg z-10">
                        <div className="mb-4 p-3 flex flex-wrap">
                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Inmate Name:</p>
                        <p className="font-semibold text-xl m-2 flex-grow">{userDetails.name}</p>
                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Hostel Admsn no:</p>
                        <p className="font-semibold text-xl m-2 flex-grow">{userDetails.hostel_admission_no}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Bill Month:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.month + " - " + messBillDetails.year}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Bill Published Date:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && DateConverter.parseDateDDMMYYYY(messBillDetails.bill_issued_date)}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Attendance:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.attendance}</p>
                            
                            <button onClick={() => {
                                setEditabele({ name: "attendance", prevValue: messBillDetails.attendance,value:0,special:false })
                            }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                Edit
                            </button>
                            {additionalDetails && additionalDetails.map((details, index) => (
                                <>
                                    <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.title}:</p>
                                    <p className="font-semibold text-xl m-2 flex-grow">{messExempt ? details.establishment_charge ? 0 : details.amount : details.amount}</p>
                                    {
                                        details.title.toLowerCase()!='daily rate' && <button onClick={() => {
                                            setEditabele({ name: details.title, prevValue: messExempt ? details.establishment_charge ? 0 : details.amount : details.amount,value:0,special:false  })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                            Edit
                                        </button>
                                    }
                                </>
                            ))}
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Charge</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.mess_charge}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Mess Extras:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.mess_extras}</p>
                            {
                                specialCharges && specialCharges.map((details, index) => (
                                    <>
                                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.title}:</p>
                                        <p className="font-semibold text-xl m-2 flex-grow">{details.amount}</p>
                                        <button onClick={() => {
                                            setEditabele({ name: details.title, prevValue:details.amount,value:0,special:true  })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                            Edit
                                        </button>
                                    </>
                                ))
                            }

                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Total Special Charges:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails &&  Math.round( messBillDetails.non_veg_charges * 100) / 100}</p>

                            {
                                nonVegCharges && nonVegCharges.map((details, index) => (
                                    <>
                                        <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">{details.item}:</p>
                                        <p className="font-semibold text-xl m-2 flex-grow">{Math.round(details.charge * 100) / 100}</p>
                                    </>
                                ))
                            }
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Sum Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.sum_amount}</p>
                            <button onClick={() => {
                                            setEditabele({ name: "Sum Amount", prevValue:messBillDetails.sum_amount,value:0,special:false })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                Edit
                            </button>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Egrants:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.egrants}</p>
                            <button onClick={() => {
                                            setEditabele({ name: "Egrants", prevValue:messBillDetails.egrants,value:0,special:false  })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                Edit
                            </button>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Total Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.total_amount}</p>
                            {/* <button onClick={() => {
                                            setEditabele({ name: "Total Amount", prevValue:messBillDetails.total_amount,value:0  })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                Edit
                            </button> */}
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Remaining Amount:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.remaining_amount}</p>
                            <button onClick={() => {
                                            setEditabele({ name: "Remaining Amount", prevValue:messBillDetails.remaining_amount,value:0  })
                                        }} className=" h-7 mt-1 px-4 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ">
                                Edit
                            </button>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Fine:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && messBillDetails.fine}</p>
                            <p className="font-semibold text-xl m-2 flex-grow-0 flex-shrink-0 w-48">Amount To Pay:</p>
                            <p className="font-semibold text-xl m-2 flex-grow">{messBillDetails && parseFloat(messBillDetails.remaining_amount) + parseFloat(messBillDetails.fine)}</p>

                        </div>
                    </div>
                </div>

                <div className="col-span-1 sm:ml-10 w-full">
                    <div>
                       {editable!=null?<p className='text-2xl font-semibold text-slate-400 mt-10'>Edit Details</p>:<p className='text-2xl font-semibold text-slate-400 mt-10'>Nothing To Edit</p>} 
                    </div>
                    {
                        editable!=null &&  <form onSubmit={handleSubmit} className="flex flex-col">
                        <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                            <label className='font-semibold' htmlFor="">Field Name :</label>{" "}
                            <input
                                required
                                placeholder='Previous Value'
                                value={editable.name}
                                readOnly
                                type="text"
                                className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                            <label className='font-semibold' htmlFor="">Previous Value :</label>{" "}
                            <input
                                required
                                placeholder='Previous Value'
                                value={editable.prevValue}
                                readOnly
                                type="number"
                                className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 w-100 gap-4 m-5 mb-3">
                            <label className='font-semibold' htmlFor="">New Value :</label>{" "}
                            <input
                                required
                                placeholder='New Value'
                                value={editable.value}
                                min={0}
                                onChange={(e)=>{
                                        updateValue(e.target.value)
                                }}
                                type="number"
                                className="w-12/12 py-2 px-3 rounded-md ring-2 ring-slate-300 focus:outline-none"
                            />
                        </div>
                        <button
                            type='submit'
                            className="w-full m-2  bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded shadow focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </form>
                    }
                   
                </div>
                <AlertDialog
                    open={open1}
                    setOpen={setOpen1}
                    modalHeading={modalHeading}
                    modalText={modalText}
                />
                <ConfirmDialog
                    open={open2}
                    setOpen={setOpen2}
                    modalHeading={modalHeading}
                    modalText={modalText}
                    confirmFunction={editMessBill}
                />
            </div>
            <div className="bg-yellow-200 text-yellow-800 p-4 mt-4 rounded-md">
                <p className="font-semibold mb-2">Warning:</p>
                <ul className="list-disc ml-6">
                    <li> Mess bill is generated using the software from 03/2024 .However due amounts and Bill Dates are copied from the manually generated bills. Since individual details of such bills are not copied they are shown as zero here </li>
                    <li>Make sure you have typed the transaction ID correct .</li>
                    <li>Ensure The date provided is correct .</li>
                    <li>Double-check the accuracy of payment details.</li>

                </ul>
            </div>

        </div>
    );
}

export default EditMessBillPage;
