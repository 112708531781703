import React, { useState, useEffect, useContext } from 'react';
import { DatePicker, Modal } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { UserContext } from '../../../Contexts/UserContext';
import { motion } from "framer-motion";
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { Calendar, ChevronLeft, ChevronRight, CalendarDays } from 'lucide-react';
import { baseUrl } from '../../../baseUrl';

const BookNonVegItems = () => {
  const [dates, setDates] = useState([]);
  const [items, setItems] = useState([]);
  const { setLoading, user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM'));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    fetchItems();
  }, [selectedMonth]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/inmate/non-veg-bookings`, {
        params: { selectedMonth, user_id: user.user_id, hostel: "LH" },
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
      setModalHeading('Error');
      setModalText('Failed to fetch booking items');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async (item_id, item, booking_start, booking_end) => {
    if (dayjs().isAfter(dayjs(booking_start)) && dayjs().isBefore(dayjs(booking_end).add(1, 'day'))) {
      try {
        await axios.post(
          `${baseUrl}/inmate/non-veg-bookings`,
          { item_id, user_id: user.user_id },
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        setModalHeading('Success');
        setModalText('Item booked successfully!');
        setOpen(true);
        fetchItems();
      } catch (error) {
        setModalHeading('Error');
        setModalText('Failed to book item');
        setOpen(true);
      }
    } else {
      setModalHeading('Notice');
      setModalText('Booking is not allowed at this time.');
      setOpen(true);
    }
  };

  const handleCancelBooking = async (item_id, item) => {
    try {
      await axios.delete(
        `${baseUrl}/inmate/non-veg-bookings/${item_id}`,
        { 
          params: { user_id: user.user_id },
          headers: { Authorization: `Bearer ${user.token}` }
        }
      );
      setModalHeading('Success');
      setModalText('Booking cancelled successfully');
      setOpen(true);
      fetchItems();
    } catch (error) {
      setModalHeading('Error');
      setModalText('Failed to cancel booking');
      setOpen(true);
    }
  };

  const confirmCancelBooking = (item_id, item) => {
    setModalHeading("Are You Sure?");
    setModalText(`You are about to cancel your booking for ${item}. Are you sure you want to continue?`);
    setOpen1(true);
    setSelectedItemId(item_id);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="p-2" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day + 1);
      const dateString = date.toISOString().split('T')[0];
      const isServed = dates.includes(dateString);
      const isToday = new Date().toDateString() === date.toDateString();

      calendarDays.push(
        <div
          key={day}
          className={`
            p-2 text-center rounded-full w-10 h-10 flex items-center justify-center mx-auto
            ${isServed ? 'bg-green-500 text-white' : 'hover:bg-gray-100'} 
            ${isToday ? 'ring-2 ring-blue-500' : ''}
            cursor-pointer transition-colors duration-200
          `}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Special Items Booking</h1>
        <div className="flex items-center gap-2">
          <DatePicker
            onChange={(date, dateString) => setSelectedMonth(dateString)}
            picker="month"
            className="w-40"
          />
        </div>
      </div>

      <div className="grid gap-4">
        {items.map((item) => (
          <motion.div
            key={item.item_id}
            whileHover={{ scale: 1.01 }}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                  <h3 className="font-semibold text-lg mb-1 text-gray-800">{item.item}</h3>
                  {item.is_booked && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      Booked
                    </span>
                  )}
                </div>

                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Booking Period</p>
                  <p className="font-medium text-gray-700">
                    {dayjs(item.booking_start).format('MMM D')} - {dayjs(item.booking_end).format('MMM D, YYYY')}
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <button 
                    className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => {
                      setDates(item.dates_served.map(date => dayjs(date).format('YYYY-MM-DD')));
                      setIsCalendarOpen(true);
                    }}
                  >
                    <CalendarDays className="w-4 h-4 mr-2" />
                    Served Dates
                  </button>
                </div>

                <div>
                  {dayjs(item.booking_end).isAfter(dayjs().subtract(1, 'day')) ? (
                    <button
                      className={`w-full px-4 py-2 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 
                        ${item.is_booked 
                          ? 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500' 
                          : 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
                        }
                        ${(dayjs().isBefore(dayjs(item.booking_start)) || dayjs().isAfter(dayjs(item.booking_end).add(1, 'day'))) 
                          ? 'opacity-50 cursor-not-allowed' 
                          : ''
                        }
                      `}
                      disabled={dayjs().isBefore(dayjs(item.booking_start)) || dayjs().isAfter(dayjs(item.booking_end).add(1, 'day'))}
                      onClick={() => item.is_booked 
                        ? confirmCancelBooking(item.item_id, item.item)
                        : handlePurchase(item.item_id, item.item, item.booking_start, item.booking_end)
                      }
                    >
                      {item.is_booked ? "Cancel Booking" : "Book Now"}
                    </button>
                  ) : (
                    <p className="text-gray-500 font-medium text-center">
                      {item.is_booked ? "Previously Booked" : "Not Booked"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <AlertDialog
        open={open}
        setOpen={setOpen}
        modalHeading={modalHeading}
        modalText={modalText}
      />

      <ConfirmDialog
        open={open1}
        setOpen={setOpen1}
        modalHeading={modalHeading}
        modalText={modalText}
        confirmFunction={() => handleCancelBooking(selectedItemId)}
      />

      <Modal
        visible={isCalendarOpen}
        onCancel={() => setIsCalendarOpen(false)}
        footer={null}
        className="max-w-md"
      >
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <h3 className="text-lg font-semibold">
              {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </h3>
            <button
              onClick={() => setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
          <div className="grid grid-cols-7 gap-2 mb-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className="font-medium text-center text-gray-500">{day}</div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-2">
            {renderCalendar()}
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-600">
            <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
            <span>Item Served On These Dates</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookNonVegItems;