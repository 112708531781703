import React, { useState, useEffect } from 'react';
import { Form, Select, InputNumber, Button, message, Tooltip, Input, DatePicker } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { baseUrl } from '../../../baseUrl';
import AlertDialog from '../../../components/AlertDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';

const { Option } = Select;

const AddPrice = () => {
    const [form] = Form.useForm();
    const item = Form.useWatch('item', form);
    const date = Form.useWatch('date', form);

    const [items, setItems] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [servedDates, setServedDates] = useState([]);
    const [modalText, setModalText] = useState("");
    const [modalHeading, setModalHeading] = useState("");
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [existingPrice, setExistingPrice] = useState(null);

    const onFinish = async (values) => {
        try {
            const response = await axios.put(`${baseUrl}/inmate/add-prices`, values);

            if (response.status === 200) {
                setModalHeading("Request Completed");
                setModalText(`Price ${existingPrice ? 'updated' : 'added'} successfully for the item ${items.find(i => i.item_id === values.item).item} on ${values.date}`);
                setOpen1(true);
                form.resetFields();
                setExistingPrice(null);
            }
        } catch (error) {
            console.error('Error adding/updating price:', error);
            message.error('Failed to add/update price');
        }
    };

    useEffect(() => {
        if (selectedMonth) {
            fetchItems();
        }
    }, [selectedMonth]);

    useEffect(() => {
        if (item && date) {
            fetchExistingPrice();
        }
    }, [item, date]);

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${baseUrl}/inmate/non-veg-items`, {
                params: {
                    selectedMonth: dayjs(selectedMonth).format('YYYY-MM'),
                    hostel: "LH"
                }
            });
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
            message.error('Failed to fetch items');
        }
    };

    const fetchExistingPrice = async () => {
        try {
            const response = await axios.get(`${baseUrl}/inmate/get-price`, {
                params: { item_id: item, date }
            });
            if (response.data) {
                setExistingPrice(response.data);
                form.setFieldsValue({
                    receiptNo: response.data.receipt_no,
                    receiptDate: dayjs(response.data.receipt_date),
                    amount: response.data.amount,
                    totalAmount: response.data.total_amount
                });
            } else {
                setExistingPrice(null);
                form.setFieldsValue({
                    receiptNo: undefined,
                    receiptDate: undefined,
                    amount: undefined,
                    totalAmount: undefined
                });
            }
        } catch (error) {
            console.error('Error fetching existing price:', error);
            message.error('Failed to fetch existing price');
        }
    };

    const onItemChange = (itemId) => {
        const selectedItem = items.find(item => item.item_id === itemId);
        if (selectedItem && selectedItem.dates_served) {
            setServedDates(selectedItem.dates_served.map(date => dayjs(date)));
        } else {
            setServedDates([]);
        }
        form.setFieldsValue({ date: undefined });
        setExistingPrice(null);
    };

    const isDateDisabled = (date) => {
        const twoDaysAgo = dayjs().subtract(2, 'day').startOf('day');
        return date.isBefore(twoDaysAgo) || date.isAfter(dayjs());
    };

    return (
        <div className="mx-auto mt-8 p-6 bg-white rounded-lg shadow-md w-8/12">
            <h2 className="text-2xl font-bold mb-6 text-center">Add/Update Special Item Price</h2>
            <Form form={form} onFinish={onFinish} layout="horizontal" className=''>
                <Form.Item
                    name="month"
                    label="Select Month"
                    rules={[{ required: true, message: 'Please select a month' }]}
                >

                    {/* <Select
                        placeholder="Select a month"
                        onChange={(value) => setSelectedMonth(dayjs(value, 'YYYY-MM'))}
                    >
                        {[...Array(12)].map((_, i) => {
                            const date = dayjs().month(i);
                            return (
                                <Option key={i} value={date.format('YYYY-MM')}>
                                    {date.format('MMMM YYYY')}
                                </Option>
                            );
                        })}
                    </Select> */}
                    <DatePicker
                        picker="month"
                        onChange={(date, dateString) => setSelectedMonth(dateString)}
                        className="w-full "
                    />
                </Form.Item>

                <Form.Item
                    name="item"
                    label="Select Item"
                    rules={[{ required: true, message: 'Please select an item' }]}
                >
                    <Select
                        placeholder="Select a non-veg item"
                        onChange={onItemChange}
                    >
                        {items.map(item => (
                            <Option key={item.item_id} value={item.item_id}>{item.item}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="date"
                    label="Select Date"
                    rules={[{ required: true, message: 'Please select a date' }]}
                >
                    <Select placeholder="Select a served date">
                        {servedDates.map(date => (
                            <Option
                                key={date.format()}
                                value={date.format('YYYY-MM-DD')}
                                disabled={isDateDisabled(date)}
                            >
                                {isDateDisabled(date) ? (
                                    <Tooltip title="Price cannot be modified for dates more than 2 days old">
                                        <span className="text-gray-400">{date.format('YYYY-MM-DD')}</span>
                                    </Tooltip>
                                ) : (
                                    date.format('YYYY-MM-DD')
                                )}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="receiptNo"
                    label="Receipt No"
                    rules={[{ required: true, message: 'Please enter the Receipt No' }]}
                >
                    <Input placeholder="Receipt No" />
                </Form.Item>

                <Form.Item
                    name="receiptDate"
                    label="Receipt Date"
                    rules={[{ required: true, message: 'Please enter the Receipt Date' }]}
                >
                    <DatePicker
                        maxDate={dayjs()}
                        className="w-full"
                        placeholder="Enter Date"
                    />
                </Form.Item>

                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[{ required: true, message: 'Please enter the Amount' }]}
                >
                    <InputNumber
                        step={0.01}
                        precision={2}
                        className="w-full"
                        placeholder="Enter Amount"
                    />
                </Form.Item>

                <Form.Item
                    name="totalAmount"
                    label="Total Amount"
                    rules={[
                        { required: true, message: 'Please enter the Total Amount' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('amount') === undefined) {
                                    return Promise.resolve();
                                }
                                if (value < getFieldValue('amount')) {
                                    return Promise.reject(new Error('Total Amount must be greater than or equal to Amount'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        step={0.01}
                        precision={2}
                        className="w-full"
                        placeholder="Enter Total Amount"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="w-full bg-blue-500 hover:bg-blue-600">
                        {existingPrice ? 'Update Price' : 'Add Price'}
                    </Button>
                </Form.Item>
            </Form>
            <AlertDialog
                open={open1}
                setOpen={setOpen1}
                modalHeading={modalHeading}
                modalText={modalText}
            />
            <ConfirmDialog
                open={open2}
                setOpen={setOpen2}
                modalHeading={modalHeading}
                modalText={modalText}
            />
        </div>
    );
};

export default AddPrice;