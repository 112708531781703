import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Table, Row, Col } from 'antd';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const { Option } = Select;
const { MonthPicker } = DatePicker;

const ViewBookings = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [items, setItems] = useState([]);
    const [servedDates, setServedDates] = useState([]);
    const [inmates, setInmates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedMonth) {
            fetchItems(dayjs(selectedMonth).format('YYYY-MM'));
        }
    }, [selectedMonth]);

    useEffect(() => {
        if (selectedItem) {
            fetchServedDates();
        }
    }, [selectedItem]);

    const fetchItems = async (selectedMonth) => {
        try {
            const response = await axios.get(`${baseUrl}/inmate/non-veg-items`, {
                params: {
                    selectedMonth: selectedMonth,
                    hostel: "LH"
                }
            });
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const fetchServedDates = async () => {
        try {
            const response = await axios.get(`${baseUrl}/inmate/served-dates`, {
                params: {
                    itemId: selectedItem,
                    month: dayjs(selectedMonth).format('YYYY-MM')
                }
            });
            setServedDates(response.data);
        } catch (error) {
            console.error('Error fetching served dates:', error);
        }
    };

    const fetchInmates = async () => {
        if (!selectedDate || !selectedItem) return;

        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/inmate/booked-inmates`, {
                params: {
                    date: selectedDate.format('YYYY-MM-DD'),
                    hostel: "LH",
                    itemId: selectedItem
                }
            });
            setInmates(response.data);
        } catch (error) {
            console.error('Error fetching inmates:', error);
        } finally {
            setLoading(false);
        }
    };
    const downloadPDF = () => {
        const doc = new jsPDF();
        const dateBefore = selectedDate.subtract(1, 'day').format('YYYY-MM-DD');
        const selectedItemName = items.find(item => item.item_id == selectedItem).item;

        // Add title
        doc.setFontSize(16);
        doc.text(`Inmates Booked ${selectedItemName} on ${dayjs(dateBefore).format('DD-MM-YYYY')}`, 14, 15);

        // Use autoTable for better formatting
        doc.autoTable({
            head: [['Hostel Admission No', 'Name']],
            body: inmates.map(inmate => [inmate.hostel_admission_no, inmate.uname]),
            startY: 25,
            styles: {
                fontSize: 10,
                cellPadding: 3,
            },
            headStyles: {
                fillColor: [76, 175, 80],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
            },
            columnStyles: {
                0: { cellWidth: 50 },
                1: { cellWidth: 'auto' },
            },
        });

        doc.save(`inmates_${selectedItemName}_${dateBefore}.pdf`);
    };
    const columns = [
        {
            title: 'Hostel Admission No',
            dataIndex: 'hostel_admission_no',
            key: 'hostel_admission_no',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'uname',
            key: 'uname',
            align: 'center',
        },
    ];

    return (
        <div className="p-4">
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={12} md={8}>
                    <MonthPicker
                        onChange={(date) => {
                            setSelectedMonth(date);
                            setSelectedItem(null);
                            setSelectedDate(null);
                        }}
                        className="w-full"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Select Item"
                        onChange={(value) => {
                            setSelectedItem(value);
                            setSelectedDate(null);
                        }}
                        disabled={!selectedMonth}
                        className="w-full"
                    >
                        {items.map((item) => (
                            <Option key={item.item_id} value={item.item_id}>
                                {item.item}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Select
                        placeholder="Select Served Date"
                        onChange={(value) => setSelectedDate(dayjs(value))}
                        disabled={!selectedItem}
                        className="w-full"
                    >
                        {servedDates.map((date) => (
                            <Option key={date} value={date}>
                                {dayjs(date).format('YYYY-MM-DD')}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <button
                        onClick={fetchInmates}
                        disabled={!selectedDate || !selectedItem}
                        className="bg-blue-500 text-white px-4 py-2 rounded w-full disabled:bg-gray-300"
                    >
                        View Inmates
                    </button>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <button
                        onClick={downloadPDF}
                        disabled={!selectedDate || !inmates.length || dayjs().isBefore(dayjs(selectedDate).subtract(1, 'day')) || dayjs().isAfter(dayjs(selectedDate).add(1, 'day'))}
                        className="bg-green-500 text-white px-4 py-2 rounded w-full disabled:bg-gray-300"
                    >
                        Download PDF
                    </button>
                </Col>
            </Row>
            <Table
                dataSource={inmates}
                columns={columns}
                loading={loading}
                rowKey="hostel_admission_no"
                pagination={{ pageSize: 100 }}
                rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
                style={{ border: '1px solid #eaeaea', minWidth: '100%' }}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2} style={{ textAlign: 'center' }}>
                                Total Inmates: {inmates.length}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </div>
    );
};

export default ViewBookings;
