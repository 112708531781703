import React, { useState, useRef, useEffect } from 'react';
import { Calendar, ChevronLeft, ChevronRight } from 'lucide-react';

const exampleDates = [
  '2024-09-15', '2024-09-20', '2024-09-25', '2024-10-05', '2024-10-10', '2024-11-15'
];

const CalendarModal = ({ dates = exampleDates }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const calendarDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="p-2"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day+1);
      const dateString = date.toISOString().split('T')[0];
      const isServed = dates.includes(dateString);
      const isToday = new Date().toDateString() === date.toDateString();

      calendarDays.push(
        <div 
          key={day} 
          className={`p-2 text-center rounded-full w-10 h-10 flex items-center justify-center mx-auto
            ${isServed ? 'bg-green-500 text-white' : 'hover:bg-gray-100'} 
            ${isToday ? 'ring-2 ring-blue-500' : ''}
            cursor-pointer transition-colors duration-200`}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200"
      >
        <Calendar className="mr-2 h-5 w-5" />
        Dates Served
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div ref={modalRef} className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-800">Service Calendar</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none text-xl"
              >
                &times;
              </button>
            </div>
            <div className="flex justify-between items-center mb-4">
              <button 
                onClick={prevMonth} 
                className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                type="button"
              >
                <ChevronLeft className="h-6 w-6 text-gray-600" />
              </button>
              <h3 className="text-xl font-semibold text-gray-700">
                {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </h3>
              <button 
                onClick={nextMonth} 
                className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                type="button"
              >
                <ChevronRight className="h-6 w-6 text-gray-600" />
              </button>
            </div>
            <div className="grid grid-cols-7 gap-2 mb-2">
              {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                <div key={day} className="font-medium text-center text-gray-500">{day}</div>
              ))}
            </div>
            <div className="grid grid-cols-7 gap-2">
              {renderCalendar()}
            </div>
            <div className="mt-4 text-sm text-gray-600">
              <span className="inline-block w-4 h-4 bg-green-500 rounded-full mr-2"></span>
              Dates with service
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarModal;