import { useState, useContext, useEffect } from "react"
import { motion } from "framer-motion"
import axios from 'axios'
import notfound from "../../images/not-found.jpg"
import { UserContext } from "../../Contexts/UserContext"
import { baseUrl } from "../../baseUrl"
import HostelApplicationForm from "./components/HostelApplicationForm"
import RankListStudent from "./components/RankListStudent"
import ViewAllotmentStudent from "./components/ViewAllotmentStudent"
import EditHostelApplicationForm from "./components/EditHostelApplicationForm"
import ApplicationStatus from "./components/ApplicationStatus"
import HostelFeeStudent from "./components/HostelFeeStudent"

function HostelApplication() {
  const [currpage, setCurrPage] = useState(1)
  const [tabSelected, setTabSelected] = useState(1)
  const [admno, setAdmno] = useState(180287)


  const { user } = useContext(UserContext)
  const { setLoading } = useContext(UserContext);
  const [appliedStatus, setAppliedStatus] = useState({})

  const [submited, setSubmited] = useState(false)
  useEffect(() => {
    axios.get(`${baseUrl}/student/checkapplied`, { params: { admission_no: user.user_id } }).then(res => {
      setAppliedStatus(res.data)
      console.log('res.data.checkapplied', res.data)
    })
    console.log("guuu")

  }, [submited])

  // useEffect(() => {
  //   axios.get(`${baseUrl}/student/get-invitation-hostel`, { params: { admision_no: user.user_id } }).then(res => {
  //   })

  // })


  // const submitHandler = () => {
  //   setDetails({ ...details, user_id: user.user_id })
  //   axios.post('http://localhost:8080/student/hostel-application', details)
  //     .then(res => console.log(res))
  // }

  return (
    <div className='flex flex-col w-full min-h-screen h-auto items-center'>
      <div className='flex flex-row justify-between w-11/12 pt-4 items-center'>
        <div className='text-xl font-bold'>Apply for Hostel</div>
        <div className='flex flex-row space-x-4 items-center'>
          <div className='bg-white border rounded-full w-10 aspect-square' />
          <div>{user.name}</div>
        </div>
      </div>
      {user.enable == false &&
        (<div className="relative py-3 mt-14 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-gray-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <h2 className="text-3xl font-bold text-center text-slate-800 mb-8">Hostel Application</h2>

            <h2 className="font-bold text-xl mb-4">Rules</h2>
            <ol className="list-decimal pl-5 pt-2 space-y-2">
              <li>Admission shall be confined to the regular full time UG/PG students who are in the current nominal roll of the college prepared from time to time by the College of Engineering, Trivandrum.</li>
              <li>The warden shall have powers to issue instructions and standing orders to regulate the internal matters of the hostel and the warden's decision shall be final in all matters connected with the hostel.</li>
              <li>Damage to hostel property will result in fines and disciplinary action. The cost of repair or replacement will be recovered from the responsible student(s).</li>
              <li>The Warden shall announce the opening of hostel admissions at the beginning of each academic year, typically within two weeks of the college's admission process completion.</li>
              <li>Eligible students may submit their hostel application forms during the designated application period.</li>


            </ol>
            {/* {appliedStatus.open_in && (
                  <div className="w-full flex items-end justify-end mt-5">
                      <motion.button
                          whileHover={{ x: 10, backgroundColor: 'green' }}
                          className="p-3 bg-stone-800 text-white rounded-xl"
                          onClick={() => setCurrPage(currpage + 1)}
                      >
                          Proceed to Apply
                      </motion.button>
                  </div>
              )} */}
            {/* <div className="mt-8">
                  <button
                      type="submit"
                      className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 transition duration-150 ease-in-out"
                  >
                      Submit Direct Allotment
                  </button>
              </div> */}

          </div>

        </div>)

      }
      {appliedStatus.checkapplied === false && appliedStatus.close_application === false && <HostelApplicationForm appliedStatus={appliedStatus} />}
      {appliedStatus.close_application === true && appliedStatus.checkapplied === false &&
        <div className="w-11/12 flex flex-col justify-center bg-white rounded-xl p-5  items-center m-3">
          <h1 className="text-xl font-bold">Last date is Over </h1>
          <img src={notfound} className="w-10/12" />

        </div>

      }

      {appliedStatus.checkapplied === true &&
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} className='w-11/12 mt-12 bg-white rounded-xl text-left p-5 overflow-y-scroll'>
          <div>
            <div className='flex flex-row text-black text-sm font-bold relative mb-5'>
              <div
                className='cursor-pointer '
                onClick={() => {
                  setTabSelected(1)
                }}
              >
                <div>View Status</div>
                <div className={tabSelected === 1 ? 'mt-2 h-1 self-center w-12/12 bg-stone-800 rounded-full' : ''} />
              </div>

              {appliedStatus.provisional_rank_list === true && appliedStatus.publish_provisional === true && appliedStatus.publish_final == false &&
                (

                  <div
                    className='ml-5 cursor-pointer'
                    onClick={() => {
                      setTabSelected(2)
                    }}
                  >
                    <div>Provisional RankList</div>
                    <div className={tabSelected === 2 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                  </div>)}
              {appliedStatus.final_rank_list && appliedStatus.publish_final &&
                (

                  <div
                    className='ml-5 cursor-pointer'
                    onClick={() => {
                      setTabSelected(3)
                    }}
                  >
                    <div>View Final RankList</div>
                    <div className={tabSelected === 3 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                  </div>)}
              {/* {appliedStatus.enable_submit_concern === true &&
                (<div
                  className='ml-5 cursor-pointer'
                  onClick={() => {
                    setTabSelected(4)
                  }}
                >

                  <div>Raise Complaint</div>
                  <div className={tabSelected === 4 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                </div>)} */}
              {appliedStatus.allotment_published &&
                (<div
                  className='ml-5 cursor-pointer'
                  onClick={() => {
                    setTabSelected(5)
                  }}
                >
                  <div>Allotment</div>
                  <div className={tabSelected === 5 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                </div>)}
              {appliedStatus.allotment_published && appliedStatus.allotment_status &&
                (<div
                  className='ml-5 cursor-pointer'
                  onClick={() => {
                    setTabSelected(6)
                  }}
                >
                  <div>Fee to be paid</div>
                  <div className={tabSelected === 6 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                </div>)}
              {appliedStatus.enable_edit &&
                (<div
                  className='ml-5 cursor-pointer'
                  onClick={() => {
                    setTabSelected(7)
                  }}
                >
                  <div>Edit / View Application</div>
                  <div className={tabSelected === 7 ? 'mt-2 h-1 w-12/12 self-center bg-stone-800 rounded-full' : ''} />
                </div>)}
            </div>
            {tabSelected === 1 &&
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className="flex flex-col items-center justify-center">
                {/* <h1 className="font-semibold text-lg mb-5 mt-3">Application Status</h1> */}
                <ApplicationStatus appliedStatus={appliedStatus} />
                {/* 
                <div className="flex items-center justify-center w-full mt-5 mb-5">
                  <div className="flex flex-col items-center justify-center p-5 bg-purple-800 rounded-lg mr-5">
                    <h2 className="text-lg font-semibold text-white">Your Rank</h2>
                    <hr />
                    <h1 className="text-2xl font-bold text-white">1</h1>
                  </div>

                  <div className="flex flex-col items-center justify-center p-5 bg-teal-700 rounded-lg ml-5">
                    <h2 className="text-lg font-semibold text-white">Status</h2>
                    <hr />
                    {appliedStatus.checkapplied && <h1 className="text-2xl font-bold text-white">Applied...</h1>}
                  </div>
                </div> */}

              </motion.div>
            }
            {tabSelected === 2 && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <h1 className="font-semibold text-lg mb-5 mt-3">RankList</h1>
              <RankListStudent admno={admno} />
            </motion.div>

            }
            {tabSelected === 3 && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <h1 className="font-semibold text-lg mb-5 mt-3">RankList</h1>
              <RankListStudent admno={admno} />
            </motion.div>

            }
            {tabSelected === 4 && <motion.div className="pt-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <form action="">
                <div className="grid grid-cols-2 gap-y-4 w-6/12">
                  <label htmlFor="">Complaint:</label>
                  <textarea name="" id="" rows="5" placeholder="Enter your complaint" className="border-solid border-2 rounded-lg ml-3 p-1" required></textarea>
                </div>
                <div className="w-full flex items-end justify-end mt-5">
                  <button className="ml-auto p-3 bg-stone-800 text-white rounded-xl">Submit Complaint</button>
                </div>

              </form>
            </motion.div>}
            {tabSelected === 5 && <motion.div className="" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <ViewAllotmentStudent />
            </motion.div>}
            {tabSelected === 6 && <motion.div className="flex justify-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <HostelFeeStudent />
            </motion.div>}
            {tabSelected === 7 && <motion.div className="mt-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
              <EditHostelApplicationForm setTabSelected={setTabSelected} />

            </motion.div>}
          </div>
        </motion.div >

      }
    </div >

  )
}

export default HostelApplication