import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Select, Button, Typography } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import { baseUrl } from '../../../baseUrl';

const { Title } = Typography;
const { Option } = Select;

const TemplateBuilder = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [inmateFields, setInmateFields] = useState([]);
    const [studentFields, setStudentFields] = useState([]);

    useEffect(() => {
        const fetchFields = async () => {
            const response = await axios.get(`${baseUrl}/warden/fields`);
            setInmateFields(response.data.inmateFields);
            setStudentFields(response.data.studentFields);
        };

        fetchFields();
    }, []);

    const handleSaveTemplate = async () => {
        const templateData = {
            content: stateToHTML(editorState.getCurrentContent()),
        };
        await axios.post(`${baseUrl}/warden/templates`, { templateData });
        alert('Template saved!');
    };

    const insertField = (fieldName) => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();

        // Insert text at the current selection
        const contentStateWithText = Modifier.insertText(
            contentState,
            selectionState,
            `{{${fieldName}}}`
        );

        const newEditorState = EditorState.push(editorState, contentStateWithText, 'insert-characters');
        setEditorState(newEditorState);
    };

    return (
        <div className="container mx-auto p-4">
            <Title level={3}>Select Fields for the Template</Title>
            <Select
                style={{ width: '300px', marginBottom: '20px' }}
                placeholder="Select a field"
                onChange={insertField}
            >
                {inmateFields.map((field) => (
                    <Option key={field} value={field}>
                        {field}
                    </Option>
                ))}
                {studentFields.map((field) => (
                    <Option key={field} value={field}>
                        {field}
                    </Option>
                ))}
            </Select>

            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarClassName="flex sticky top-0 bg-white z-1000"
                wrapperClassName="mt-4 border border-gray-300 rounded-md"
                editorClassName="border border-gray-300 p-4 rounded-md min-h-[200px]"
            />
            <Button type="primary" onClick={handleSaveTemplate} className="mt-4">
                Save Template
            </Button>
        </div>
    );
};

export default TemplateBuilder;
